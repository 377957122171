import {useState} from 'react'
import useMediaQuery from '@mui/material/useMediaQuery';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DemoPopper from '../popper/DemoPopper';

const RestaurantUseCase = () => {
	const mobileDeviceTrue = useMediaQuery('(max-width:600px)');

	const [anchorElDemoPopper, setAnchorElDemoPopper] = useState(null);

	const handleClickDemoPopper = (event) => {
	  setAnchorElDemoPopper(event.currentTarget);
	  setOpenDemoPopper(true)
	};
  
	const [openDemoPopper,setOpenDemoPopper] = useState(false) ;
	return(
		<div className="colhead">
			<div 
				style={{
					display:mobileDeviceTrue ? "" : "flex",
					flexDirection:""
				}}
				>
					<div className="col2-left" style={{
						padding:"0 10px",
					}}>
						<div style={{textAlign:"left"}}>
							<div style={{marginBottom:"2rem"}}>
								<h2>Restaurant</h2>
							</div>
							<h4>Automate your reservations.</h4><br></br>
							<h4>Constantly receive new reservations, for instance, via Instagram DM or post comments. The CalendarApp finds free tables and handles the booking process on it's own.</h4><br></br>
							<h4>You do delicious food - the CalendarApp does reservations.</h4><br></br>
							
						</div>

						<div
						style={{
							display:"flex"
						}}>
							<button
							className="button-save"
							onClick={handleClickDemoPopper}
							>
								Get Demo
							</button>
						</div>

						
					</div>

					<div
					style={{
						width:"100%",
						display:"flex",
						justifyContent:"center",

					}}>
						<div className="col" 
						style={{
							display:"flex",
							justifyContent:"center",
							minHeight:"",
							minWidth:"",
							// margin:"2rem 0"
						}}
						>
							{/* <img src="https://storage.cloud.google.com/calendarapp-data-bucket/LandingPage/Restaurant%20Demo%201.png" height="100%"/> */}
							<img src="https://storage.googleapis.com/calendarapp-data-bucket/LandingPage/Restaurant%20Demo%201.png" height="100%" width=""/>
						</div>
						{/* <div className="col" 
						style={{
							display:"flex",
							justifyContent:"center",
							minHeight:"",
							minWidth:"",
							margin:"2rem 0"
						}}
						>
							<img src="https://storage.cloud.google.com/calendarapp-data-bucket/LandingPage/Restaurant%20Demo%201.png" height="100%"/>
						</div> */}
					</div>

					<DemoPopper 
						open={openDemoPopper}
						setOpenDemoPopper={setOpenDemoPopper}
						anchorElDemoPopper={anchorElDemoPopper}
						setAnchorElDemoPopper={setAnchorElDemoPopper}
						placement={'right-start'} 
						useCase="restaurant"
					/>
					<div></div>
			</div>
		</div>
	)
}

const RealEstateUseCase = () => {

	const [anchorElDemoPopper, setAnchorElDemoPopper] = useState(null);
	const [openDemoPopper,setOpenDemoPopper] = useState(false) ;
	const handleClickDemoPopper = (event) => {
	  setAnchorElDemoPopper(event.currentTarget);
	  setOpenDemoPopper(true)
	};

	return(
		<div className="colhead"
				style={{
					display:"flex",flexDirection:""}}
				>
					<div className="col2-left" 
					style={{
						padding:"0 10px"
					}}>
						<div style={{textAlign:"left"}}>
							<div style={{marginBottom:"2rem"}}>
								<h2>Real Estate</h2>
							</div>
							<h4>Are you looking for new clients?</h4><br></br>
							<h4>Bring home owners and buyers together. The CalendarApps handles your open houses as well as your sales conversations.</h4><br></br>
							<h4>For more dream houses and less scheduling nightmares.</h4><br></br>
							
						</div>

						<div
						style={{
							display:"flex"
						}}>
							<button
							className="button-save"
							onClick={handleClickDemoPopper}	
							>
								Get Demo
							</button>
						</div>

						
					</div>

					<div
					style={{
						display:"flex",
						width:"100%",
						justifyContent:"center",
					}}>
						<div className="col" 
						style={{
							display:"flex",
							justifyContent:"center",
							minHeight:"",
							minWidth:"",
							// margin:"2rem 0"
						}}
						>
							{/* <img src="https://storage.cloud.google.com/calendarapp-data-bucket/LandingPage/Restaurant%20Demo%201.png" height="100%"/> */}
							<img src="https://storage.googleapis.com/calendarapp-data-bucket/LandingPage/CalendarApp%20Real%20Estate%20Demo.png" height="750px" width=""/>
						</div>
						{/* <div className="col" 
						style={{
							display:"flex",
							justifyContent:"center",
							minHeight:"",
							minWidth:"",
							margin:"2rem 0"
						}}
						>
							<img src="https://storage.cloud.google.com/calendarapp-data-bucket/LandingPage/Restaurant%20Demo%201.png" height="100%"/>
						</div> */}
					</div>

					<DemoPopper 
						open={openDemoPopper}
						setOpenDemoPopper={setOpenDemoPopper}
						anchorElDemoPopper={anchorElDemoPopper}
						setAnchorElDemoPopper={setAnchorElDemoPopper}
						placement={'right-start'} 
						useCase="real estate"
					/>
				</div>
	)
}
const HotelUseCase = () => {
	return(
		<div className="colhead"
				style={{
					display:"flex",flexDirection:""}}
				>
					<div className="col2-left" 
					style={{
						padding:"0 10px"
					}}>
						<div style={{textAlign:"left"}}>
							<div style={{marginBottom:"2rem"}}>
								<h2>Accomodation</h2>
							</div>
							<h4></h4><br></br>
							<h4>
								Choose between a sophisticated room show and a simple calendar picker.
								Handling bookings conversationally has never been easier.
							</h4><br></br>
							<h4>For better nights - for better stays</h4><br></br>
							
						</div>

						<div
						style={{
							display:"flex"
						}}>
							<button
							className="button-save"
							>
								View Demo
							</button>
						</div>

						
					</div>
					<div
					style={{
						display:"flex",
						width:"100%",
						justifyContent:"center",
					}}>
						<div className="col" 
						style={{
							display:"flex",
							justifyContent:"center",
							minHeight:"",
							minWidth:"",
							margin:"2rem 0"
						}}
						>
							{/* <img src="https://storage.cloud.google.com/calendarapp-data-bucket/LandingPage/Restaurant%20Demo%201.png" height="100%"/> */}
							<img src="https://storage.googleapis.com/calendarapp-data-bucket/LandingPage/Demo%20CalendarApp%20Accommodation_.png" height="750px" width=""/>
						</div>
						{/* <div className="col" 
						style={{
							display:"flex",
							justifyContent:"center",
							minHeight:"",
							minWidth:"",
							margin:"2rem 0"
						}}
						>
							<img src="https://storage.cloud.google.com/calendarapp-data-bucket/LandingPage/Restaurant%20Demo%201.png" height="100%"/>
						</div> */}
					</div>
				</div>
	)
}
const MedicalUseCase = () => {
	return(
		<div className="colhead"
				style={{
					display:"flex",flexDirection:""}}
				>
					<div className="col2-left" 
					style={{
						padding:"0 10px"
					}}>
						<div style={{textAlign:"left"}}>
							<div style={{marginBottom:"2rem"}}>
								<h2>Doctor,Dentist & other Healthcare Professionals</h2>
							</div>
							<h4></h4><br></br>
							<h4>
							A good recovery requires great care for your patience
							</h4><br></br>
							<h4>Let your patiences book an appointment with you via social media.
							You can make your patiences choose the doctor they trust most.</h4><br></br>
							
						</div>

						<div
						style={{
							display:"flex"
						}}>
							<button
							className="button-save"
							>
								View Demo
							</button>
						</div>

						
					</div>
					<div className="col" 
					style={{
						display:"flex",
						justifyContent:"center",
						minHeight:"",
						minWidth:"",
						margin:"2rem 0"
					}}
					>
						<img src="https://deroche-moore.de/wp-content/uploads/2022/05/Google-Calendar.png" height="100px"/>
					</div>
				</div>
	)
}
const GymUseCase = () => {
	return(
		<div className="colhead"
				style={{
					display:"flex",flexDirection:""}}
				>
					<div className="col2-left" 
					style={{
						padding:"0 10px"
					}}>
						<div style={{textAlign:"left"}}>
							<div style={{marginBottom:"2rem"}}>
								<h2>Gym, Yoga Studios & other sports classes</h2>
							</div>
							<h4></h4><br></br>
							<h4>
							We all know: sport keeps us physically and mentally healthy. Being busy with scheduling bookings does the opposite. Put yourself in a good place with the CalendarApp.
							</h4><br></br>
							<h4>Group Classes or personal trainings. Everything is possible. Just do it.</h4><br></br>
							
						</div>

						<div
						style={{
							display:"flex"
						}}>
							<button
							className="button-save"
							>
								View Demo
							</button>
						</div>

						
					</div>
					<div className="col" 
					style={{
						display:"flex",
						justifyContent:"center",
						minHeight:"",
						minWidth:"",
						margin:"2rem 0"
					}}
					>
						<img src="https://deroche-moore.de/wp-content/uploads/2022/05/Google-Calendar.png" height="100px"/>
					</div>
				</div>
	)
}
const SalesUseCase = () => {
	return(
		<div className="colhead"
				style={{
					display:"flex",flexDirection:""}}
				>
					<div className="col2-left" 
					style={{
						padding:"0 10px"
					}}>
						<div style={{textAlign:"left"}}>
							<div style={{marginBottom:"2rem"}}>
								<h2>Sales</h2>
							</div>
							<h4></h4><br></br>
							<h4>
							Skyrock your next quarter by scheduling calls with hot leads faster.
							No matter if your sales team has 1 or 100 agents. The CalendarApp helps you scale.

							</h4><br></br>
							<h4>You do the sales - the CalendarApp does the rest.</h4><br></br>
							
						</div>

						<div
						style={{
							display:"flex"
						}}>
							<button
							className="button-save"
							>
								View Demo
							</button>
						</div>

						
					</div>
					<div className="col" 
					style={{
						display:"flex",
						justifyContent:"center",
						minHeight:"",
						minWidth:"",
						margin:"2rem 0"
					}}
					>
						<img src="https://deroche-moore.de/wp-content/uploads/2022/05/Google-Calendar.png" height="100px"/>
					</div>
				</div>
	)
}
const BeautyUseCase = () => {
	return(
		<div className="colhead"
				style={{
					display:"flex",flexDirection:""}}
				>
					<div className="col2-left" 
					style={{
						padding:"0 10px"
					}}>
						<div style={{textAlign:"left"}}>
							<div style={{marginBottom:"2rem"}}>
								<h2>Hairdressers, Makeup Studios & other beauty services</h2>
							</div>
							<h4></h4><br></br>
							<h4>
							The beauty is in the detail and details take time. Valuable time that's wasted with scheduling.

							Let your customers choose who make them feel most beautiful. 
							</h4><br></br>
							<h4>It's as easy as swiping on Tinder. But way more fun.
							</h4><br></br>
							
						</div>

						<div
						style={{
							display:"flex"
						}}>
							<button
							className="button-save"
							>
								View Demo
							</button>
						</div>

						
					</div>
					<div className="col" 
					style={{
						display:"flex",
						justifyContent:"center",
						minHeight:"",
						minWidth:"",
						margin:"2rem 0"
					}}
					>
						<img src="https://deroche-moore.de/wp-content/uploads/2022/05/Google-Calendar.png" height="100px"/>
					</div>
				</div>
	)
}
const AutomotiveUseCase = () => {
	return(
		<div className="colhead"
				style={{
					display:"flex",flexDirection:""}}
				>
					<div className="col2-left" 
					style={{
						padding:"0 10px"
					}}>
						<div style={{textAlign:"left"}}>
							<div style={{marginBottom:"2rem"}}>
								<h2>Automotive</h2>
							</div>
							<h4></h4><br></br>
							<h4>
							Purchasing or servicing a car can be stressful. Not with the CalendarApp.

							Your customers can book test drives, repairs, rents and much more.
							</h4><br></br>
							<h4>Making a booking at your business is more convenient than the Tesla Autopilot.
							</h4><br></br>
							
						</div>

						<div
						style={{
							display:"flex"
						}}>
							<button
							className="button-save"
							>
								View Demo
							</button>
						</div>

						
					</div>
					<div className="col" 
					style={{
						display:"flex",
						justifyContent:"center",
						minHeight:"",
						minWidth:"",
						margin:"2rem 0"
					}}
					>
						<img src="https://deroche-moore.de/wp-content/uploads/2022/05/Google-Calendar.png" height="100px"/>
					</div>
				</div>
	)
}
const OtherUseCase = () => {
	return(
		<div className="colhead"
				style={{
					display:"flex",flexDirection:""}}
				>
					<div className="col2-left" 
					style={{
						padding:"0 10px"
					}}>
						<div style={{textAlign:"left"}}>
							<div style={{marginBottom:"2rem"}}>
								<h2>Customize your bookings</h2>
							</div>
							<h4></h4><br></br>
							<h4>
							Your don't find your business in our list? No worries.
							Simply build your own use cases based on single or group events.

							</h4><br></br>
							<h4>Possibilities are endless with the CalendarApp.
							</h4><br></br>
							
						</div>

						<div
						style={{
							display:"flex"
						}}>
							<button
							className="button-save"
							>
								View Demo
							</button>
						</div>

						
					</div>
					<div className="col" 
					style={{
						display:"flex",
						justifyContent:"center",
						minHeight:"",
						minWidth:"",
						margin:"2rem 0"
					}}
					>
						<img src="https://deroche-moore.de/wp-content/uploads/2022/05/Google-Calendar.png" height="100px"/>
					</div>
				</div>
	)
}

const ColorToggleButton = ({changingUseCase}) => {
	const [alignment, setAlignment] = useState('Restaurant');

  
	const handleChange = (event, newAlignment) => {
	  setAlignment(newAlignment);
	  changingUseCase(newAlignment)
	};

	const mobileDeviceTrue = useMediaQuery('(max-width:600px)');


  
	return (
	  <ToggleButtonGroup
		color="primary"
		value={alignment}
		exclusive
		onChange={handleChange}
		sx={{
			display:mobileDeviceTrue ? "block" : ""
		}}
	  >
		<ToggleButton 
		onClick={() => {
			console.log("Restaurant clicked")
		}}
		value="Restaurant" sx={{border:mobileDeviceTrue ? "0" : ""}}>Restaurant</ToggleButton>
		<ToggleButton value="Real Estate" sx={{border:mobileDeviceTrue ? "0" : ""}}>Real Estate</ToggleButton>
		<ToggleButton value="Accommodation" sx={{border:mobileDeviceTrue ? "0" : ""}}>Hotel</ToggleButton>
		<ToggleButton value="Medical" sx={{border:mobileDeviceTrue ? "0" : ""}}>Medical</ToggleButton>
		<ToggleButton value="Gym" sx={{border:mobileDeviceTrue ? "0" : ""}}>Gym</ToggleButton>
		<ToggleButton value="Sales" sx={{border:mobileDeviceTrue ? "0" : ""}}>Sales</ToggleButton>
		<ToggleButton value="Automotive" sx={{border:mobileDeviceTrue ? "0" : ""}}>Automotive</ToggleButton>
		<ToggleButton value="Beauty" sx={{border:mobileDeviceTrue ? "0" : ""}}>Beauty</ToggleButton>
		<ToggleButton value="Other" sx={{border:mobileDeviceTrue ? "0" : ""}}>Other</ToggleButton>
	  </ToggleButtonGroup>
	);
  }

const UseCasesBox = () => {

	const [hideRestaurantUseCase, setHideRestaurantUseCase] = useState(false);
	const [hideRealEstateUseCase, setHideRealEstateUseCase] = useState(true);
	const [hideAccommodationUseCase, setHideAccommodationUseCase] = useState(true);
	const [hideGymUseCase, setHideGymUseCase] = useState(true);
	const [hideMedicalUseCase, setHideMedicalUseCase] = useState(true);
	const [hideSalesUseCase, setHideSalesUseCase] = useState(true);
	const [hideBeautyUseCase, setHideBeautyUseCase] = useState(true);
	const [hideAutomotiveUseCase, setHideAutomotiveUseCase] = useState(true);
	const [hideOtherUseCase, setHideOtherUseCase] = useState(true);

	const mobileDeviceTrue = useMediaQuery('(max-width:600px)');

	const changingUseCase = (useCase) => {
		console.log("useCase")

		if(useCase === "Restaurant"){
			setHideRestaurantUseCase(false)

			setHideRealEstateUseCase(true)
			setHideAccommodationUseCase(true)
			setHideMedicalUseCase(true)
			setHideBeautyUseCase(true)
			setHideSalesUseCase(true)
			setHideAutomotiveUseCase(true)
			setHideOtherUseCase(true)
			setHideGymUseCase(true)




		}
		if(useCase === "Real Estate"){
			setHideRealEstateUseCase(false)

			setHideRestaurantUseCase(true)
			setHideAccommodationUseCase(true)
			setHideMedicalUseCase(true)
			setHideSalesUseCase(true)
			setHideBeautyUseCase(true)
			setHideAutomotiveUseCase(true)
			setHideOtherUseCase(true)
			setHideGymUseCase(true)

		}
		if(useCase === "Accommodation"){
			setHideAccommodationUseCase(false)

			setHideRestaurantUseCase(true)
			setHideRealEstateUseCase(true)
			setHideMedicalUseCase(true)
			setHideSalesUseCase(true)
			setHideBeautyUseCase(true)
			setHideAutomotiveUseCase(true)
			setHideOtherUseCase(true)
			setHideGymUseCase(true)

		}
		if(useCase === "Gym"){
			setHideGymUseCase(false)
			
			setHideMedicalUseCase(true)
			setHideRestaurantUseCase(true)
			setHideRealEstateUseCase(true)
			setHideAccommodationUseCase(true)
			setHideSalesUseCase(true)
			setHideBeautyUseCase(true)
			setHideAutomotiveUseCase(true)
			setHideOtherUseCase(true)
		}
		if(useCase === "Medical"){
			setHideMedicalUseCase(false)

			setHideRestaurantUseCase(true)
			setHideRealEstateUseCase(true)
			setHideAccommodationUseCase(true)
			setHideSalesUseCase(true)
			setHideBeautyUseCase(true)
			setHideAutomotiveUseCase(true)
			setHideOtherUseCase(true)
			setHideGymUseCase(true)

		}
		if(useCase === "Sales"){
			setHideRestaurantUseCase(true)
			setHideRealEstateUseCase(true)
			setHideAccommodationUseCase(true)
			setHideMedicalUseCase(true)
			setHideBeautyUseCase(true)
			setHideAutomotiveUseCase(true)
			setHideOtherUseCase(true)
			setHideGymUseCase(true)


			setHideSalesUseCase(false)
		}
		if(useCase === "Automotive"){
			setHideRestaurantUseCase(true)
			setHideRealEstateUseCase(true)
			setHideAccommodationUseCase(true)
			setHideMedicalUseCase(true)
			setHideSalesUseCase(true)
			setHideBeautyUseCase(true)
			setHideOtherUseCase(true)
			setHideGymUseCase(true)



			setHideAutomotiveUseCase(false)
		}
		if(useCase === "Beauty"){
			setHideRestaurantUseCase(true)
			setHideRealEstateUseCase(true)
			setHideAccommodationUseCase(true)
			setHideMedicalUseCase(true)
			setHideSalesUseCase(true)
			setHideAutomotiveUseCase(true)
			setHideOtherUseCase(true)
			setHideGymUseCase(true)


			setHideBeautyUseCase(false)
		}
		if(useCase === "Other"){
			setHideRestaurantUseCase(true)
			setHideRealEstateUseCase(true)
			setHideAccommodationUseCase(true)
			setHideMedicalUseCase(true)
			setHideSalesUseCase(true)
			setHideAutomotiveUseCase(true)
			setHideBeautyUseCase(true)
			setHideGymUseCase(true)


			setHideOtherUseCase(false)
		}
	}



    return (
        <>
            <div 
			className="container"
			style={{
				margin:"5rem auto"
			}}
			>

                <div className="row" style={{textAlign:"center"}}>
					<div
					className="flex-column">
						<div className="col mt-3 mb-3">
							<h2 id="demo">Tailored to your Business</h2>
						</div>
						<div 
						className=""
						style={{
							margin:mobileDeviceTrue ? "1rem 0 1rem 0" : "2rem 0 5rem 0"
						}}
						>
							<ColorToggleButton 
							changingUseCase={changingUseCase}
							/>
						</div>

					</div>
					{hideRestaurantUseCase ? null : <RestaurantUseCase />}
					{hideRealEstateUseCase ? null : <RealEstateUseCase />}
					{hideAccommodationUseCase ? null : <HotelUseCase />}
					{hideMedicalUseCase ? null : <MedicalUseCase />}
					{hideGymUseCase ? null : <GymUseCase />}
					{hideSalesUseCase ? null : <SalesUseCase />}
					{hideBeautyUseCase? null : <BeautyUseCase />}
					{hideAutomotiveUseCase ? null : <AutomotiveUseCase />}
					{hideOtherUseCase ? null : <OtherUseCase />}
					<div className="w-100"></div>



					

                    <div className="w-100" style={{marginBottom:"3rem"}}></div>
                    
                </div>

            </div>
        </>
    )
}

export default UseCasesBox
