import CookieConsent from "react-cookie-consent";
import useMediaQuery from '@mui/material/useMediaQuery';

const CalendarAppCookieConsent = () => {
	const mobileDeviceTrue = useMediaQuery('(max-width:600px)');
  return (
	<div>
		<div
			style={{
				display:"flex",
				justifyContent:"center",
				margin:"0 auto",
				width:"50%",
			}}>
				<CookieConsent 
				// debug={true}
				// enableDeclineButton flipButtons
					location="bottom"
					buttonText="Agree"
					declineButtonText="Settings"
					cookieName="cookie_agreement"
					style={{ 
						background: "#2B373B",
						width:mobileDeviceTrue ? "" : "50%",
						borderTopRightRadius:"10px",
						borderTopLeftRadius:"10px",
						left:"unset"
					}}
					buttonStyle={{ 
						background: "#4caf50",
						color:"white",
						fontSize: "15px",
						fontWeight: "bold",
						borderRadius: "10px",
						margin:"0 15px",
						padding:"10px 20px"
					}}
					declineButtonStyle={{
						background:"white",
						color:"black",
						borderRadius:"10px"
					}}
					buttonWrapperClasses="cookie-consent-wrap-buttons"
					expires={150}
					>
					To make the CalendarApp work, we use cookies. By using the CalendarApp, you agree to our Privacy and Cookie Policy.
					Read more about our policy and set your preferences <a target="_blank" href="/data-privacy" style={{ fontSize: "16px",textDecoration:"" }}> here</a>
					{" "}
				</CookieConsent>

			</div>
	</div>
  )
}

export default CalendarAppCookieConsent