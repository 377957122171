
import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function FAQBox() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const faq = [
	  {
		  name:"panel1",
		  title:"Can I test the CalendarApp for free?",
		  answer:"Yes! You can test the CalendarApp for free for 14 days. No credit card is required. The trail ends automatically."
	  },
	  {
		  name:"panel2",
		  title:"Which Channels are supported?",
		  answer:"Currently supported channels are: \n - Messenger \n - Instagram \n - Whatsapp \n - Telegram \n - Google Maps \n \n Channels that will be supported in the future:\n - LinkedIn \n - SMS \n - Signal \n - Viber \n"
	  },
	  {
		  name:"panel3",
		  title:"Does the CalendarApp support Google Calendar?",
		  answer:"Yes! All your appointments are stored in your Google Calendar. Soon we will offer Outlook and Office 365 calendars as well."
	  },
	  {
		  name:"panel4",
		  title:"Which use cases does the CalendarApp support?",
		  answer:"The CalendarApp serves a wide range of use cases and business. Appointments can be booked for groups or single events. A few examples are: Restaurants, Real Estate Agents, Car Dealers, Doctors and professional medical staff, Hairdresses, Nail studios and beauty salons, Gyms, yoga and other sports classes,online and offline events, & many more"
	  },
	  {
		  name:"panel5",
		  title:"How do I use the CalendarApp?",
		  answer:"After signing up for free you obtain credentials which you need to use to connect the CalendarApp to your Manychat account. After selecting a use case and managing your calendar settings you are ready to receive appointments."
	  },
	  {
		  name:"panel6",
		  title:"Is there any training or support available?",
		  answer:"Yes! There is! We will provide you with amazing help docs and lots of great videos. Our support team is always happy to help you."
	  },
	  {
		  name:"panel7",
		  title:"Can all text and buttons be customized?",
		  answer:"Yes! All buttons, text and Manychat flows can be 100% customized so they address to your audience precisely. We also offer translated templates in 6 different languages."
	  },
	  {
		  name:"panel8",
		  title:"I run an agency. Can I use one account for all my clients?",
		  answer:"The CalendarApp is used a lot by agencies. We made it very simple for you to serve all your clients' businesses in one CalendarApp account."
	  },
	  {
		  name:"panel9",
		  title:"Can I get a demo?",
		  answer:"Sure! Click the button, choose a channel and your business."
	  },
  ]

  return (
    <div className="lp-faq" 
	>
		<div className=""
		style={{
			margin:"0rem 0 1rem 0",
			textAlign:"center"
		}}>
                    <h2>What's on your mind?</h2>
		</div>

		{faq.map((faq,faqIndex) => {
			return(
				<div>
					<Accordion expanded={expanded === faq.name} onChange={handleChange(faq.name)}>
						<AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
						<Typography>{faq.title}</Typography>
						</AccordionSummary>
						<AccordionDetails>
						<Typography>
							{faq.answer}
						</Typography>
						</AccordionDetails>
					</Accordion>
				</div>
			)
		})}

    </div>
  );
}
