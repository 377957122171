
const Register = ({buttonText,referrer}) => {

    return (
        <div>
            <button className="btn-cta1" onClick={() => window.open("/signup?ref=" + referrer)}>
				{buttonText || "Get started for free"}
			</button>
            
        </div>
    )
}

export default Register
