import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery';

const ConnectionBox = () => {

	const mobileDeviceTrue = useMediaQuery('(max-width:600px)');

    return (
        <>
            <div 
			className="container"
			style={{
				margin:"15rem auto",
				background: "#F2F2F2",
				borderRadius: "20px",
				padding: "2rem",
			}}
			>
                <div className="row" style={{textAlign:"center"}}>
					<div className="colhead"
					style={{
						display:"flex",flexDirection:""}}
					>
						<div className="col2-left" style={{}}>
						<div style={{textAlign:"left"}}>
							<div style={{
								margin:"0 0 2rem 0",
								padding:"0 10px"
							}}
							className="lp-titlebox-text"
							>
								<h2>Fast and Reliable Connection </h2>
							</div>
							<div
							style={{
								padding:"0 10px"
							}}>
								<h3 style={{
								fontWeight:"400"
							}}>The CalendarApp creates the connection between the worlds most poular social media platforms & calendar apps</h3>

							</div>
							
						</div>
							
						</div>
						<div
						style={{
							display:"flex",
							justifyContent: "space-around",
							width: "100%",
    						padding: mobileDeviceTrue ? "" : "0 15%",
						}}>
							<div className="col" 
							style={{
								display:"flex",
								justifyContent:"center",
								minHeight:"",
								minWidth:"",
								margin:"2rem 0"
							}}
							>
								<img src="https://deroche-moore.de/wp-content/uploads/2022/05/Google-Calendar.png" height=""/>
							</div>

							<div className="col" 
							style={{
								display:"flex",
								justifyContent:"center",
								minHeight:"",
								minWidth:"",
								margin:"2rem 0"
							}}
							>
								<img src="https://storage.googleapis.com/calendarapp-data-bucket/Logo/logo192.png" height="130px"/>
							</div>
						</div>
					</div>
                    <div className="w-100" style={{marginBottom:"3rem"}}></div>
                    
                </div>

            </div>
        </>
    )
}

export default ConnectionBox
