
import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function FAQBoxFewo() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const faq = [
	  {
		  name:"panel1",
		  title:"Kann ich die CalendarApp kostenfrei testen?",
		  answer:"Ja! Du kannst die CalendarApp gratis für 14 Tage testen. Eine Kreditkarte ist nicht erforderlich. Die Testphase endet automatisch."
	  },
	  {
		  name:"panel2",
		  title:"Wie kann ein Interessent auf Kleinanzeigen.de eine Ferienwohnung bei mir buchen?",
		  answer:"Du fügst deinem bestehenden Inserat entweder eine Telefonnummer bei, unter der dich der Interessent anschreiben kann. Oder du kopierst deinen persönlichen Buchungslink in die Beschreibung. Die öffnet der Interessent und bucht dann seinen Aufenthalt."
	  },
	  {
		  name:"panel3",
		  title:"Wo werden die Buchungen gespeichert?",
		  answer:"Alleine deine Buchungen werden in deinem Google Kalender gespeichert. Jede Wohnung/jedes Zimmer hat einen Kalender. So hast du alleine deine Buchungen im Blick."
	  },
	  {
		  name:"panel4",
		  title:"Ich benutze bereits diverse Buchungssysteme. Wie kann ich meine Buchungen importieren?",
		  answer:"Da die CalendarApp den Google Kalender verwendet, kannst du deine bestehenden Systeme ganz einfach syncronisieren. Auf unserer Hilfeseite calendar-app.de/blog/fewo-sync findest du einen Schritt für Schritt Anleitung für die häufig verwendeten Buchungsplatformen."
	  },
	  {
		  name:"panel5",
		  title:"Kann die CalendarApp auch Zahlungen abwickeln?",
		  answer:"Ja! Um direkt Zahlungen deiner Gäste abwickeln zu können, benötigst du lediglich ein Konto bei dem Zahlungsdienstleister Stripe. Dein Konto bei Stripe kannst du in 2 einfachen Schritten mit dem CalendarApp Konto verbinden. Weitere Fragen rund um die Einrichtung und Verknüpfung findest du hier: calendar-app.de/blog/fewo-stripe . "
	  },
	  {
		  name:"panel6",
		  title:"Welche Provision fällt für Buchungen an?",
		  answer:"Wenn die CalendarApp die Zahlung für dich abwickelt, fällt eine Provision von 2,5% auf den Buchungsbetrag an. Bei 1000€ also 25€. Wenn du die Zahlung anderweitig abwickelst, fällt keine Gebühr an. Beachte: Stripe veranschlagt zusätzlich eine Gebühr."
	  },
	  {
		  name:"panel7",
		  title:"Was passiert im Falle einer Stornierung?",
		  answer:"Bestätigst du die Stornierungsanfrage deines Gasts, wird dir ebenfalls die an uns gezahlte Provision ganz oder teilweise erstattet."
	  },
	  {
		  name:"panel8",
		  title:"Wen kann ich bei Fragen kontaktieren?",
		  answer:"Unser Support-Team! Wir stehen dir bei Fragen gerne per Chat, per EMail oder telefonisch zur Verfügung."
	  },
	  {
		  name:"panel9",
		  title:"Kann ich deine Demo ansehen?",
		  answer:"Ja! Klicke auf den Button und buche deine Traumferienwohnung!"
	  },
	  {
		  name:"panel10",
		  title:"Wie kann ich Whatsapp oder Telegram einbinden?",
		  answer:"Für die Einbindung von Messenger Diensten haben wir jeweils ausführliche Hilfe-Seiten erstellt. Die kannst du für Whatsapp calendar-app.de/blog/fewo/Whatsapp und für Telegram calendar-app.de/blog/fewo/Telegram einsehen."
	  },
	  {
		  name:"panel11",
		  title:"Fallen für Messenger Dienste weitere Kosten an?",
		  answer:"Die Nutzung von Telegram ist gratis! Whatsapp ist kostenpflichtig. Weitere Infos findest du auf der Hilfe-Seite."
	  },
	  {
		  name:"panel12",
		  title:"Benötige ich für die Nutzung von Whatsapp eine neue Handynummer?",
		  answer:"Du kannst eine Handynummer nur entweder auf deinem Handy oder über die CalendarApp nutzen. Sofern du beides nutzen willst, benötigst du eine 2. Handynummer. Weitere Infos findest du auf der Hilfe-Seite."
	  },
  ]

  return (
    <div className="lp-faq" 
	style={{
		// margin:"20vh auto"
	}}
	>
		<div className=""
		style={{
			margin:"0 0 3rem 0",
			textAlign:"center"
		}}>
			<h2>Häufig gestellte Fragen</h2>
		</div>

		{faq.map((faq,faqIndex) => {
			return(
				<div>
					<Accordion expanded={expanded === faq.name} onChange={handleChange(faq.name)}>
						<AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
						<Typography>{faq.title}</Typography>
						</AccordionSummary>
						<AccordionDetails>
						<Typography>
							{faq.answer}
						</Typography>
						</AccordionDetails>
					</Accordion>
				</div>
			)
		})}

    </div>
  );
}
