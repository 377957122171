import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress';


const LoadingFallback = () => {
    return (
        <>
		<div style={{display: "flex",justifyContent: "space-evenly",position: "relative",marginTop: "20%",transform: "translateY(-50%)"}}>
			<CircularProgress size="5rem" />
		</div>
            
        </>
    )
}

export default LoadingFallback
