import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import 'firebase/storage';

const app = firebase.initializeApp({
    apiKey: "AIzaSyDKPEcGVy4WampVT7rT29npCAR6YTCmt7U",
    authDomain: "calendarapp-303815.firebaseapp.com",
    projectId: "calendarapp-303815",
    storageBucket: "calendarapp-303815.appspot.com",
    messagingSenderId: "269605859420",
    appId: "1:269605859420:web:7ddf554d7c3c423a8eb699",
    measurementId: "G-BZLCP0N1TC"
  })

export const auth = app.auth()
export const firestore = firebase.firestore()
export const firebase_ = firebase


