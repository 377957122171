import React, { useContext, useState, useEffect } from "react"
import {auth} from "../components/Firebase"
import firebase from "firebase/app"

const AuthContext = React.createContext()
const SidebarContext = React.createContext()


export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)

  function login() {
    const provider = new firebase.auth.GoogleAuthProvider();
	provider.setCustomParameters({
		prompt: 'select_account',
	  });
    return auth.signInWithPopup(provider)
  }

  function logout() {
    return auth.signOut()
  }

  function facebookLogin() {
    const provider = new firebase.auth.FacebookAuthProvider();
	// provider.addScope({
	// 	// prompt: 'select_account',
	//   });
	provider.addScope('instagram_manage_comments')
	provider.addScope('instagram_basic')
	provider.addScope('instagram_manage_messages') 
	provider.addScope('pages_manage_metadata')
	provider.addScope('business_management')
	provider.addScope('pages_show_list')
	provider.addScope('pages_messaging')
	provider.addScope('pages_read_engagement')
	provider.addScope('public_profile')
    return auth.signInWithPopup(provider)
  }


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
		if(user !== null){
			// console.log(user.providerData[0].providerId)
			if(user.providerData[0].providerId === "facebook.com"){
				console.log("facebook")
				console.log(user)
				console.log(user.providerData[0].uid)
				console.log(user.providerData[0].email)
				console.log("user.providerData[0].accessToken",user.providerData[0].accessToken)
			} else {
			setCurrentUser(user)
			}
		}
		setCurrentUser(user)
		// console.log(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    login,
    logout,
	facebookLogin
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}


	export function useSidebar() {
		return useContext(SidebarContext)
	}

	export function SidebarProvider({ children }) {
		const [showSidebar,setShowSidebar] = useState(true)

		function adjustSideBar() {
			// setShowSidebar(!showSidebar)
			console.log("changing sidebar: ",showSidebar)
			setShowSidebar(!showSidebar)

			// return showSidebar
			// return "!showSidebar"
		}

		function achsooo(){
		}

		const value = {
			adjustSideBar,
			showSidebar,
			achsooo
		}

	return (
		<SidebarContext.Provider value={value}>
			{children}
		</SidebarContext.Provider>
	)
	}