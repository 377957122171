import React from 'react'
import Header from "./Header"
import TitleBox from "./TitleBox"
import PlatformBox from "./PlatformBox"
import PricingBox from "./PricingBox"
import Footer from "./Footer"
import ConnectionBox from './ConnectionBox'
import UseCasesBox from './UseCasesBox'
import FAQBox from './FAQBox'
import CalendarAppCookieConsent from '../../CalendarAppCookieConsent'
import LandingPageReminders from './LandingPageReminders'

const LandingPage = () => {
	
    return (
        <>
            <Header />
            <TitleBox />
            <PlatformBox />
            <PricingBox />
			<ConnectionBox />
			<UseCasesBox />
			<FAQBox />
			<LandingPageReminders />
            <Footer />
			<CalendarAppCookieConsent />
        </>
    )
}

export default LandingPage
