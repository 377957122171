import {useState} from 'react'
import Header from "./Header"
// import PlatformBox from "./PlatformBox"
// import PricingBox from "./PricingBox"
import Footer from "./Footer"
// import ConnectionBox from './ConnectionBox'
import UseCasesBox from './UseCasesBox'
import FAQBoxFewo from './FAQBoxFewo'
import CalendarAppCookieConsent from '../../CalendarAppCookieConsent'
import LandingPageReminders from './LandingPageReminders'
import useMediaQuery from '@mui/material/useMediaQuery';
import Register from '../Register'
import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

const LandingPageFewo = () => {

	const TitleBox = () => {

		const mobileDeviceTrue = useMediaQuery('(max-width:600px)');
	
		return (
			<>
				<div className="container">
					<div className="" style={{textAlign:"center"}}>

						<div className="colhead"
						style={{
							display:"flex",
							flexDirection:"",
							height:mobileDeviceTrue ? "" : "100vh"
						}}
						>
							<div className="col2-left" style={{}}>
							<div 
							className="lp-titlebox-text"
							style={{
							}}
							>
								<div style={{marginBottom:"2rem"}}>
									<h1>Ferienwohnungen buchen</h1>
								</div>
								<h2
								style={{
									fontWeight:"400"
								}}>
									<b>Buchungsanfragen</b> von Kleinanzeigen.de über Whatsapp & Co. kinderleicht <b>automatisieren</b> 
								</h2>
								<div 
								className='lp-cta-btn'
								style={{}}
								>
									{/* <button>Get started for free</button> */}
									<div className="marginb-3">
										<button className="btn-cta1" 
										onClick={() => window.open("/signUpPageFewo")}>
											Kostenfrei registieren
										</button>
									</div>
									<div className="marginb-3">
										<button
										className="btn-cta1"
										style={{
											color:"#4CAF50",
											backgroundColor:"white"
										}}>
											<div
											className='f'
											>
												<div>Video ansehen</div>
												<div style={{margin:"0 0 0 5px"}}><PlayCircleIcon /></div>
											</div>
											
											
										</button>
									</div>
								</div>
							</div>
								
							</div>
							<div className="" 
							style={{display:"flex",justifyContent:"center",
							minHeight:"",minWidth:"50%",padding:"1rem 0 0 0"}}
							>

							<img 
							src="https://storage.googleapis.com/calendarapp-data-bucket/Logo/%5Bremoval.ai%5D_e9b03896-cc4e-4fb7-b5f4-b5ea28ae6cd5-app-mockup.png" 
							height={mobileDeviceTrue ? "95%" : "80%"}
							/>
								
							{/* <video autoplay muted
							width={mobileDeviceTrue ? "95%" : "80%"}
							height={mobileDeviceTrue ? "95%" : "80%"}
							autoPlay={true}
							loop={true}
							style={{
								display:"flex",
								margin:"0 auto"
							}}
							>
							<source src="https://storage.googleapis.com/calendarapp-data-bucket/LandingPage/CalendarApp%20Demo.webm" type="video/webm"></source>
							<source src="https://storage.googleapis.com/calendarapp-data-bucket/CalendarApp%20Demo.mp4" type="video/mp4"></source>
	
							Sorry, your browser doesn't support embedded videos.
							</video> */}
							</div>
						</div>

						<div className="w-100" style={{marginBottom:"3rem"}}></div>
						
					</div>
	
				</div>
			</>
		)
	}

	const ConnectionBox = () => {

		const mobileDeviceTrue = useMediaQuery('(max-width:600px)');

		const [contactSales,setContactSales] = useState(false)
		const [requestCallback,setRequestCallback] = useState(false)
	
		return (
			<>
				<div 
				className="container"
				style={{
					margin:"0 auto 10vh auto",
					background: "#F2F2F2",
					borderRadius: "20px",
					padding: "2rem",
				}}
				>
					{contactSales ? 
					<div id="contact-sales"
					className="" style={{textAlign:"center"}}>
						<div className="colhead"
						style={{
							display:"flex",
							flexDirection:"column"
						}}
						>
							<div className="col2-left" style={{maxWidth:"unset"}}>
								<div style={{textAlign:"center"}}>
									<div style={{
										margin:"0 0 2rem 0",
										padding:"0 10px",
										textAlign:"center"
									}}
									className="lp-titlebox-text"
									>
										<h2>Vermiete kinderleicht über <strong>Kleinanzeigen</strong></h2>
									</div>
									<div
									style={{
										padding:"0 10px",
										textAlign:"center"
									}}>
										<h3 style={{
										fontWeight:"400"
									}}>Wie möchtest du uns kontaktieren?</h3>
		
									</div>
									
								</div>
							</div>

							<div>
								<div className='f'
								style={{
									justifyContent:"center"
								}}>

									<div 
									className='lp-cta-btn'
									
									>
										{/* <button>Get started for free</button> */}
										<div className="marginb-3">
											
										<button className="btn-cta1" 
										// onClick={() => window.open("/signup?ref=" + referrer)}
										style={{
											color:"#4CAF50",
											border:"none",
											background:"unset",
											margin:"0 5px",
											padding:""
										}}>
											Whatsapp
										</button>
										</div>
									</div>

									<div 
									className='lp-cta-btn'
									style={{
										
									}}
									
									>
										{/* <button>Get started for free</button> */}
										<div className="marginb-3">
											
										<button className="btn-cta1" 
										// onClick={() => window.open("/signup?ref=" + referrer)}
										style={{
											color:"#4CAF50",
											border:"none",
											background:"unset",
											margin:"0 5px",
											padding:""
										}}>
											Telegram
										</button>
										</div>
									</div>

									<div 
									className='lp-cta-btn'
									
									>
										{/* <button>Get started for free</button> */}
										<div className="marginb-3">
											
										<button className="btn-cta1" 
										// onClick={() => window.open("/signup?ref=" + referrer)}
										style={{
											color:"#4CAF50",
											border:"none",
											background:"unset",
											margin:"0 5px",
											padding:""
										}}>
											per Mail
										</button>
										</div>
									</div>

									<div 
									className='lp-cta-btn'
									
									>
										{/* <button>Get started for free</button> */}
										<div className="marginb-3">
											
										<button className="btn-cta1" 
										// onClick={() => window.open("/signup?ref=" + referrer)}
										style={{
											color:"#4CAF50",
											border:"none",
											background:"unset",
											margin:"0 5px",
											padding:""
										}}>
											per Chat
										</button>
										</div>
									</div>

									<div 
									className='lp-cta-btn'
									
									>
										{/* <button>Get started for free</button> */}
										<div className="marginb-3">
											
										<button className="btn-cta1" 
										// onClick={() => window.open("/signup?ref=" + referrer)}
										style={{
											color:"#4CAF50",
											border:"none",
											background:"unset",
											margin:"0 5px",
											padding:""
										}}>
											Telefon
										</button>
										</div>
									</div>
									
									<div 
									className='lp-cta-btn'
									
									>
										{/* <button>Get started for free</button> */}
										<div className="marginb-3">
											
										<button className="btn-cta1" 
										// onClick={() => window.open("/signup?ref=" + referrer)}
										style={{
											color:"#4CAF50",
											border:"none",
											background:"unset",
											margin:"0 5px",
											padding:""
										}}>
											Rückruf vereinbaren
										</button>
										</div>
									</div>

								</div>
							</div>
							
						</div>
						<div className="w-100" style={{marginBottom:"3rem"}}></div>
						
					</div>:

					<div className="" style={{textAlign:"center"}}>
						<div className="colhead"
						style={{
							display:"flex",
							flexDirection:"column"
						}}
						>
							<div className="col2-left" style={{maxWidth:"unset"}}>
								<div style={{textAlign:"center"}}>
									<div style={{
										margin:"0 0 2rem 0",
										padding:"0 10px",
										textAlign:"center"
									}}
									className="lp-titlebox-text"
									>
										<h2>Vermiete kinderleicht über <strong>Kleinanzeigen</strong></h2>
									</div>
									<div
									style={{
										padding:"0 10px",
										textAlign:"center"
									}}>
										<h3 style={{
										fontWeight:"400"
									}}><strong>Automatisiere</strong> deine Vermietungen. <br></br> Für mehr <strong>Zufriedenheit</strong> bei Dir & deinen Gästen.</h3>
		
									</div>
									
								</div>
							</div>

							<div>
								<div className='f'
								style={{
									justifyContent:"center"
								}}>
									<div 
									className='lp-cta-btn'
									style={{}}
									>
										{/* <button>Get started for free</button> */}
										<div className="marginb-3">
										<button className="btn-cta1" 
										onClick={() => window.open("/signUpPageFewo")}>
											Kostenfrei Anmelden
										</button>
										</div>
									</div>
									<div 
									className='lp-cta-btn'
									
									>
										{/* <button>Get started for free</button> */}
										<div className="marginb-3">
											
										<button className="btn-cta1" 
										onClick={() => setContactSales(true)}
										style={{
											color:"#4CAF50",
											background:"#ffffff"
										}}>
											Vertrieb Kontaktieren
										</button>
										</div>
									</div>

								</div>
							</div>
							
						</div>
						<div className="w-100" style={{marginBottom:"3rem"}}></div>
						
					</div>}
	
				</div>
			</>
		)
	}

	const TestimonialBox = () => {
		const mobileDeviceTrue = useMediaQuery('(max-width:600px)');
	
		return (
			<>
				<div style={{margin:"5rem"}}></div>

				<div className="container" style={{margin:"",height:mobileDeviceTrue ? "" : "80vh"}}>
					<div className="" style={{textAlign:"center"}}>

						<div className=" mt-2" style={{textAlign:"center"}}> 
							<h2 style={{marginBottom:"3rem"}}>Vermieter vertrauen uns</h2>
						</div>
						<div className="w-100 mt-2"></div>
	
						<div className="img3- lp-testimonial-box" 
						style={{
							background:"#F2F2F2",
							borderRadius:"20px"
						}}
					>
							<div className="lp-testimonial flex-column-center"
							style={{
								width:mobileDeviceTrue ? "" :"50%",
								maxWidth:"unset"
							}}>
								<div>
									<img src="https://storage.googleapis.com/calendarapp-data-bucket/Steffano%20Ilg.jpg" height="120px"
									style={{
										borderRadius:"50%"
									}}/>
								</div>
								<div>
									<h5>Stephan</h5>
								</div>
								<div>
									<h5>Hat bisher über Airbnb vermietet</h5>
								</div>
								<div style={{textAlign:"left",marginTop:"2rem"}}>
									<h4
									style={{
										fontWeight:"400",
										fontSize:"18px"
									}}>
									Als Besitzer einer Ferienwohnung in Dänemark habe ich stets nach einer effizienten Lösung gesucht, um Buchungen zu verwalten. <br></br>
									Früher benötigte ich mehrere Anwendungen, um meine Buchungen zu koordinieren. Die Automatisierung der Buchungsprozesse durch die CalendarApp ist einfach fantastisch und spart mir Zeit und Mühe. 
									</h4>
								</div>
							</div>
							
							
							<div className="lp-testimonial flex-column-center"
							style={{
								width:mobileDeviceTrue ? "" :"50%",
								maxWidth:"unset"
							}}>
								<div>
									<img src="https://storage.googleapis.com/calendarapp-data-bucket/Leon.jpeg" height="120px"
									style={{
										borderRadius:"50%"
									}}/>
								</div>
								<div>
									<h5>Leon</h5>
								</div>
								<div>
									<h5>Vermieter von 3 Fewos</h5>
								</div>
								<div style={{textAlign:"left",marginTop:"2rem"}}>
									<h4
									style={{
										fontWeight:"400",
										fontSize:"18px"
									}}>
									Als Besitzer einer Ferienwohnung in Dänemark habe ich stets nach einer effizienten Lösung gesucht, um Buchungen zu verwalten. <br></br>
									Früher benötigte ich mehrere Anwendungen, um meine Buchungen zu koordinieren. Die Automatisierung der Buchungsprozesse durch die CalendarApp ist einfach fantastisch und spart mir Zeit und Mühe. 
									</h4>
								</div>
							</div>
	
						</div>
					</div>
	
				</div>
			</>
		)
	}

	const USPBox = () => {

		const mobileDeviceTrue = useMediaQuery('(max-width:600px)');
	
		return (
			<>
				<div className="container">
					<div className="" style={{textAlign:"center"}}>

						<div>
							<h2> Vorteile der CalendarApp</h2>
						</div>

						{/* <div className="w-100" style={{margin:"10vh 0"}}></div> */}

						<div id="individual"
						className="colhead"
						style={{
							display:"flex",
							flexDirection:"",
							// height:mobileDeviceTrue ? "" : "100vh"
						}}
						>
							<div className="col2-left" style={{}}>
							<div 
							className="lp-titlebox-text"
							style={{
							}}
							>
								<div style={{marginBottom:"2rem"}}>
									<h3>100% Individualisierbar</h3>
								</div>
								<h4
								style={{
									fontWeight:"400",
									fontSize:"18px",
									padding:mobileDeviceTrue ? "0 10px" : ""
								}}>
									Passe jedes Detail der Vermeitung an Deine Bedürfnisse an. Von saisonalen Preisen bis hin zur Verwaltung von Sonderwünschen Deiner Gäste – alles ist möglich.
								</h4>
								
							</div>
								
							</div>
							<div className="" 
							style={{display:"flex",justifyContent:"center",
							minHeight:"",minWidth:"50%",padding:"1rem 0 0 0"}}
							>

							<img src="https://storage.googleapis.com/calendarapp-data-bucket/Individualisiere%20die%20CalendarApp%20auf%20deine%20Bed%C3%BCrfnisse.jpg" 
							style={{
								maxHeight:mobileDeviceTrue ? "333px" :"400px"

							}}/>
							
							</div>
						</div>

						<div className="w-100" style={{margin:"10vh 0"}}></div>

						<div id="conversation"
						className="colhead"
						style={{
							display:"flex",
							flexDirection:"",
							// height:mobileDeviceTrue ? "" : "100vh"
						}}
						>

							<div className="" 
							style={{display:"flex",justifyContent:"center",
							minHeight:"",minWidth:"50%",padding:"1rem 0 0 0"}}
							>

								<img 
								style={{
									borderRadius:"10px",
									maxHeight:mobileDeviceTrue ? "200px" :"300px"
								}}
								src="https://storage.googleapis.com/calendarapp-data-bucket/Automatisiere%20Konversationen%20und%20Zahlungen%20mit%20der%20CalendarApp.jpg" 
								/>
							
							</div>

							<div className="col2-left" style={{}}>

								<div 
								className="lp-titlebox-text"
								style={{
								}}
								>
									<div style={{marginBottom:"2rem"}}>
										<h3>Automatisierte Konversation & Bezahlung</h3>
									</div>
									<h4
									style={{
										fontWeight:"400",
										fontSize:"18px",
										textAlign:mobileDeviceTrue ? "left" : "",
										padding:mobileDeviceTrue ? "0 10px" : ""
									}}>
										<ul>
											<li>
												<strong>Automatisierte KI-gestützte Kommunikation</strong> <br></br>Die intelligente CalendarApp KI beantwortet Fragen Deiner Gäste in Echtzeit, direkt über die bevorzugten Messenger-Dienste. Ob Verfügbarkeiten, Ausstattungsdetails oder Informationen zur Umgebung . Das lästige Chatten auf Kleinanzeigen gehört der Vergangenheit an. <br></br>Sollte das Gespräch ins Stocken geraten, kannst du jederzeit übernehmen.
											</li>
											<li style={{margin:"10px 0"}}>
												<strong>Nahtlose Zahlungsabwicklung</strong> <br></br>Integriere einen Zahlungsanbieter für eine direkte, sichere und unkomplizierte Buchungen. So beginnt der erholsame Urlaub schon bei der Buchung.
											</li>
										</ul> 
									</h4>
								</div>
								
							</div>

						</div>

						<div className="w-100" style={{margin:"10vh 0"}}></div>

						<div id="sync"
						className="colhead"
						style={{
							display:"flex",
							flexDirection:"",
							// height:mobileDeviceTrue ? "" : "100vh"
						}}
						>
							<div className="col2-left" style={{}}>
							<div 
							className="lp-titlebox-text"
							style={{
							}}
							>
								<div style={{marginBottom:"2rem"}}>
									<h3>Super Simpel Synchronisiert</h3>
								</div>
								<h4
								style={{
									fontWeight:"400",
									fontSize:"18px",
									padding:mobileDeviceTrue ? "0 10px" : ""
								}}>
									Verknüpfe die CalendarApp mühelos mit Deinen bestehenden Buchungssystemen durch Synchronisierung mit dem Google Kalender.
								</h4>
								<div 
								className='lp-cta-btn'
								style={{}}
								>
									<div className="marginb-3">
										<button className="btn-cta1" 
										onClick={() => window.open("/signUpPageFewo")}>
											Kostenfrei Anmelden
										</button>
										</div>
								</div>
							</div>
								
							</div>
							<div className="" 
							style={{display:"flex",justifyContent:"center",
							minHeight:"",minWidth:"50%",padding:"1rem 0 0 0"}}
							>

							<img 
							style={{
								borderRadius:"10px",
								maxHeight:mobileDeviceTrue ? "250px" :"350px"
							}}
							src="https://storage.googleapis.com/calendarapp-data-bucket/verkn%C3%BCpfe%20den%20Google%20Kalender%20um%20deine%20Buchungen%20zu%20synchronisieren.jpg" 
							maxHeight="400px"/>
							
							</div>
						</div>

						{/* <div className="w-100" style={{margin:"20vh 0"}}></div> */}
						
					</div>
	
				</div>
			</>
		)
	}

	const PricingBox = () => {

		const mobileDeviceTrue = useMediaQuery('(max-width:600px)');
	
		return (
			<>
			<div className="container">
				<div className="row" style={{textAlign:"center",marginTop:"5rem"}}>
					<div className=" mt-3 mb-3">
						<h2 id="pricing">Preise</h2>
					</div>
					<div className="w-100"></div>
					<div 
					className="pricing"
						// style={{display:"flex",justifyContent:"center",padding:"2rem"}}
						>
						<div style={{display:"flex",justifyContent:"center"}}>
							<div className="pricingBox" >
								<div className="pricingBoxContent" 
								>
									<h4>Premium</h4>
								</div>
								<div 
								style={{
									display:"flex",
								}}>
									<div
									style={{
										fontSize: "53px",
										fontWeight: "bold",
									}}>
										19€
									</div>
									<div
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "end",
										padding: "0 0 15px 0"
									}}>
										/Monat
									</div>
								</div>
	
								<div style={{
									textAlign:"left",
									margin:"1rem 0"
								}}>
									<div>
										Das Premium Produkt. Jederzeit kündbar
									</div>
								</div>
	
								<div style={{
									display:"flex",
									margin:"5px 0"
								}}>
									<div>
										<CheckIcon />
									</div>
									<div
									className="flex-column-center">
										<div
										style={{
											margin:"0 0 0 10px",
											fontWeight:"bold"
										}}>
											1 Google Konto
										</div>
									</div>
								</div>
	
								<div style={{
									display:"flex",
									margin:"5px 0"
								}}>
									<div>
										<CheckIcon />
									</div>
									<div
									className="flex-column-center">
										<div
										style={{
											margin:"0 0 0 10px",
											fontWeight:"bold"
										}}>
											Unbegrent Kalender & Events
										</div>
									</div>
								</div>
	
								<div style={{
									display:"flex",
									margin:"5px 0"
								}}>
									<div>
										<CheckIcon />
									</div>
									<div
									className="flex-column-center">
										<div
										style={{
											margin:"0 0 0 10px",
											fontWeight:"bold"
										}}>
											Premium Support via Chat & Telefon & Zoom
										</div>
									</div>
								</div>
	
								<div style={{
									display:"flex",
									margin:"5px 0px 1rem 0"
								}}>
									<div>
										<CheckIcon />
									</div>
									<div
									className="flex-column-center">
										<div
										style={{
											margin:"0 0 0 10px",
											fontWeight:"bold"
										}}>
											14 Tage kostenfrei testen
										</div>
									</div>
								</div>
	
								<div className=" mt-3"
								style={{
								}}
								>
									<button className="btn-cta1" 
										onClick={() => window.open("/signUpPageFewo")}>
											Kostenfrei Anmelden
										</button>
								</div>
							</div>
						</div>
						<div style={{display:"flex",justifyContent:"center"}}>
							<div className="pricingBox" >
								<div className="pricingBoxContent" 
								>
									<h4>Premium+</h4>
								</div>
								<div 
								style={{
									display:"flex",
								}}>
									<div
									style={{
										fontSize: "53px",
										fontWeight: "bold",
									}}>
										2,5%
									</div>
									<div
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "end",
										padding: "0 0 15px 0"
									}}>
										/Transaktionswert
									</div>
								</div>
	
								<div style={{
									textAlign:"left",
									margin:"1rem 0"
								}}>
									<div>
										Stripe Zahlungsabwicklung
									</div>
								</div>
	
								<div style={{
									display:"flex",
									margin:"5px 0"
								}}>
									<div>
										<CheckIcon />
									</div>
									<div
									className="flex-column-center">
										<div
										style={{
											margin:"0 0 0 10px",
											fontWeight:"bold"
										}}>
											alles aus Premium & zusätzlich:
										</div>
									</div>
								</div>
	
								<div style={{
									display:"flex",
									margin:"5px 0"
								}}>
									<div>
										<CheckIcon />
									</div>
									<div
									className="flex-column-center">
										<div
										style={{
											margin:"0 0 0 10px",
											fontWeight:"bold"
										}}>
											Zahlungsabwicklung über Stripe
										</div>
									</div>
								</div>
	
								<div style={{
									display:"flex",
									margin:"5px 0"
								}}>
									<div>
										<CheckIcon />
									</div>
									<div
									className="flex-column-center">
										<div
										style={{
											margin:"0 0 0 10px",
											fontWeight:"bold"
										}}>
											100% Rückerstattung bei Stornierungen
										</div>
									</div>
								</div>
	
								<div style={{
									display:"flex",
									margin:"5px 0px 1rem 0"
								}}>
									<div>
										<CheckIcon />
									</div>
									<div
									className="flex-column-center">
										<div
										style={{
											margin:"0 0 0 10px",
											fontWeight:"bold"
										}}>
											Updates inkludiert
										</div>
									</div>
								</div>
	
								<div className=" mt-3"
								style={{
								}}
								>
									<button className="btn-cta1" 
										onClick={() => window.open("/signUpPageFewo")}>
											Kostenfrei Anmelden
										</button>
								</div>
							</div>
						</div>
	
						<div style={{display:"flex",justifyContent:"center"}}>
							<div className="pricingBox" >
								<div className="pricingBoxContent" 
								>
									<h4>Einrichtungsgebühr</h4>
								</div>
								<div 
								style={{
									display:"flex",
								}}>
									<div
									style={{
										fontSize: "53px",
										fontWeight: "bold",
									}}>
										299€
									</div>
									<div
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "end",
										padding: "0 0 15px 0"
									}}>
										(optional)
									</div>
								</div>
	
								<div style={{
									textAlign:"left",
									margin:"1rem 0"
								}}>
									<div>
										Deine Ferienwohnung von A - Z
									</div>
								</div>
	
								<div style={{
									display:"flex",
									margin:"5px 0"
								}}>
									<div>
										<CheckIcon />
									</div>
									<div
									className="flex-column-center">
										<div
										style={{
											margin:"0 0 0 10px",
											fontWeight:"bold"
										}}>
											Einmalige Einrichtung durch unser Support Team
										</div>
									</div>
								</div>
	
								<div style={{
									display:"flex",
									margin:"5px 0"
								}}>
									<div>
										<CheckIcon />
									</div>
									<div
									className="flex-column-center">
										<div
										style={{
											margin:"0 0 0 10px",
											fontWeight:"bold"
										}}>
											Entspannt & stressfrei zu deinen Buchungen
										</div>
									</div>
								</div>
	
								<div style={{
									display:"flex",
									margin:"5px 0"
								}}>
									<div>
										<CheckIcon />
									</div>
									<div
									className="flex-column-center">
										<div
										style={{
											margin:"0 0 0 10px",
											fontWeight:"bold"
										}}>
											Einrichtung ist optionale Zusatzleistung
										</div>
									</div>
								</div>
	
								<div style={{
									display:"none",
									margin:"5px 0px 1rem 0"
								}}>
									<div>
										<CheckIcon />
									</div>
									<div
									className="flex-column-center">
										<div
										style={{
											margin:"0 0 0 10px",
											fontWeight:"bold"
										}}>
											All updates included
										</div>
									</div>
								</div>
	
								<div className=""
								style={{
									margin:"40px 0 0 0 "
								}}
								>
									<button className="btn-cta1" 
										onClick={() => window.open("/signUpPageFewo")}>
											Vertrieb Kontaktieren
									</button>
								</div>
							</div>
						</div>
						
						
					</div>
					
				</div>
	
			</div>
			</>
		)
	}

	const PlatformBox = () => {

		const mobileDeviceTrue = useMediaQuery('(max-width:600px)');
	
		const customStyles = {
			container: base => ({
				...base,
				flex:1
			})
		}
	
		const platformOptions = [
			{value:"Whatsapp", label:"Whatsapp"},
			{value:"Instagram", label:"Instagram"},
			{value:"Google Maps", label:"Google Maps"},
			{value:"Telegram", label:"Telegram"},
			{value:"LinkedIn", label:"LinkedIn"},
			{value:"Signal", label:"Signal"},
			{value:"WeChat", label:"WeChat"},
			{value:"iMessage", label:"iMessage"}
		]
	
		const [image,setImage] = useState("messenger")
		const [activeImageId, setActiveImageId] = useState(1);
	
		const images = [
			{ src: 'https://storage.googleapis.com/calendarapp-data-bucket/Logo/Platform%20Messenger.png', id: 1 },
			{ src: 'https://storage.googleapis.com/calendarapp-data-bucket/Logo/Platform%20Instagram.png', id: 2 },
			{ src: 'https://storage.googleapis.com/calendarapp-data-bucket/Logo/Platform%20Whatsapp.png', id: 3 },
			{ src: 'https://storage.googleapis.com/calendarapp-data-bucket/Logo/Platform%20Telegram.png', id: 4 },
			{ src: 'https://storage.googleapis.com/calendarapp-data-bucket/Logo/Platform%20Google.png', id: 5 },
		  ];

		  const steps = [
			// {
			//   label: 'Kostenfrei registieren',
			//   description: ``,
			// },
			{
			  label: 'Google Kalender',
			  description:
				'',
			},
			{
			  label: 'Vorlage anpassen',
			  description: ``,
			},
			{
			  label: 'Stripe einbinden',
			  description: ``,
			},
			{
			  label: 'Messenger einbinden',
			  description: ``,
			},
			{
			  label: 'Link teilen',
			  description: ``,
			},
		  ];

		  const [activeStep, setActiveStep] = useState(0);

		  const handleNext = () => {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		  };
		
		  const handleBack = () => {
			setActiveStep((prevActiveStep) => prevActiveStep - 1);
		  };
		
		  const handleReset = () => {
			setActiveStep(0);
		  };
		  
	
		return (
			<>
				<div className="" style={{marginTop:"",height:mobileDeviceTrue ? "" : "100vh",display:"flex",flexDirection:"column",justifyContent:"space-around",width:""}}>
					<div
					style={{
						width:mobileDeviceTrue ? "90%" : "80%",
						margin:"0 auto"
					}}>
						<div className="">
							<h2 style={{marginBottom:"3rem",textAlign:"center"}}>
								In wenigen Schritten zum Inserat
							</h2>
						</div>
	
						<div
						className='f'
						style={{
							width:"100%",
							height:"100%",
							// flexDirection:mobileDeviceTrue ? "column" : "column",
							flexDirection:"row"
						}}>
							{/* <div
							style={{
								// width:mobileDeviceTrue ? "100%" : "50%",
								display:"flex",
								// flexDirection:mobileDeviceTrue ? "row" : "column"
								flexDirection:"column"
							}}>
	
								<div onClick={() => setActiveImageId(1)}
								className=""
								style={{
									height:mobileDeviceTrue ? "" : "",
									margin:mobileDeviceTrue ? "" : "5px"
									}}>
									<div className={mobileDeviceTrue ? "" : 'lp-background-fewo'}>
										<div className='f'
										style={{
											width:"100%",
											flexDirection:mobileDeviceTrue ? "column" : "row"
										}}>
											{mobileDeviceTrue ? null : <div className='flex-column-center'>
												<h6 style={{
												fontWeight:""
												}}>Kostenfrei registieren</h6>
											</div>}
											
										</div>
									</div>
								</div>
	
								<div onClick={() => setActiveImageId(2)}
								
								className="" 
								style={{
									height:mobileDeviceTrue ? "" : "",
									margin:mobileDeviceTrue ? "" : "5px"
								}}>
									<div className={mobileDeviceTrue ? "" : 'lp-background-fewo'}>
										<div className='f'
										style={{
											width:"100%"
										}}>
											{mobileDeviceTrue ? null : <div className='flex-column-center'>
												<h6 style={{
												fontWeight:""
												}}>Google Kalender verbinden</h6>
											</div>}
										</div>
										
									</div>
								</div>
	
								<div onClick={() => setActiveImageId(3)} className="" 
								style={{
									height:mobileDeviceTrue ? "" : "",
									margin:mobileDeviceTrue ? "" : "5px"
								}}>
									<div className={mobileDeviceTrue ? "" : 'lp-background-fewo'}>
										<div className='f'
										style={{
											width:"100%"
										}}>
											{mobileDeviceTrue ? null : <div className='flex-column-center'>
												<h6 style={{
												fontWeight:""
												}}>Vorlage anpassen</h6>
											</div>}
										</div>
										
									</div>
								</div>
	
								<div onClick={() => setActiveImageId(4)} className="" 
								style={{
									height:mobileDeviceTrue ? "" : "",
									margin:mobileDeviceTrue ? "" : "5px"
								}}>
									<div className={mobileDeviceTrue ? "" : 'lp-background-fewo'}>
										<div className='f'
										style={{
											width:"100%"
										}}>
											<div className='f'
										style={{
											width:"100%"
										}}>
											{mobileDeviceTrue ? null : <div className='flex-column-center'>
												<h6 style={{
												fontWeight:""
												}}>Stripe einbinden</h6>
											</div>}
										</div>
										</div>
										
									</div>
								</div>
	
								<div onClick={() => setActiveImageId(5)} className="" 
								style={{
									height:mobileDeviceTrue ? "" : "",
									margin:mobileDeviceTrue ? "" : "5px"
								}}>
									<div className={mobileDeviceTrue ? "" : 'lp-background-fewo'}>
										<div className='f'
										style={{
											width:"100%"
										}}>
											{mobileDeviceTrue ? null : <div className='flex-column-center'>
												<h6 style={{
												fontWeight:""
												}}>Messenger einbinden</h6>
											</div>}
										</div>
									</div>
								</div>

	
								<div onClick={() => setActiveImageId(5)} className="" 
								style={{
									height:mobileDeviceTrue ? "" : "",
									margin:mobileDeviceTrue ? "" : "30px 0"
								}}>
									<div className={mobileDeviceTrue ? "" : 'lp-background-fewo'}>
										<div className='f'
										style={{
											width:"100%"
										}}>
											{mobileDeviceTrue ? null : 
											<div className='flex-column-center'>
												<h6 style={{
												fontWeight:""
												}}>Link teilen</h6>
											</div>}
										</div>
									</div>
								</div>
	
							</div> */}

							<Box sx={{ width: 400 }}>
							<Stepper activeStep={activeStep} 
							orientation="vertical" >
							{/* orientation={mobileDeviceTrue ? "horizontal" :"vertical"} > */}
								{steps.map((step, index) => (
								<Step
								className='hover'
								key={step.label}
								onClick={() => setActiveStep(index)}>
									<StepLabel
									style={{
										fontWeight:"bold"
									}}
									sx={{
										fontWeight:"bold"
									}}
									// optional={
									// 	index === 2 ? (
									// 	<Typography variant="caption">Last step</Typography>
									// 	) : null
									// }
									>
									{step.label}
									</StepLabel>
									<StepContent>
									<Typography>{step.description}</Typography>
									{/* <Box sx={{ mb: 2 }}>
										<div>
										<Button
											variant="contained"
											onClick={handleNext}
											sx={{ mt: 1, mr: 1 }}
										>
											{index === steps.length - 1 ? 'Finish' : 'Continue'}
										</Button>
										<Button
											disabled={index === 0}
											onClick={handleBack}
											sx={{ mt: 1, mr: 1 }}
										>
											Back
										</Button>
										</div>
									</Box> */}
									</StepContent>
								</Step>
								))}
							</Stepper>
							{activeStep === steps.length && (
								<Paper square elevation={0} sx={{ p: 3 }}>
								<Typography>All steps completed - you&apos;re finished</Typography>
								<Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
									Reset
								</Button>
								</Paper>
							)}
							</Box>
	
							<div
							id="image"
							style={{
								// width:mobileDeviceTrue ? "100%" : "50%",
							}}>
								<div
								style={{
									width:"100%",
									display:"flex",
									justifyContent:"center",
	
								}}>
									<div className="" 
									style={{
										display:"flex",
										justifyContent:"center",
										minHeight:"",
										minWidth:"",
									}}
									>
										{images.map(({ src, id }) => (
										<img
											key={id}
											src="https://storage.googleapis.com/calendarapp-data-bucket/091e8399-4c6b-495c-aaf1-199102773199.png"
											// src={src}
											className={`platform-image-lp ${activeImageId === id ? 'active' : ''}`}
											alt={`Image ${id}`}
											/>
										))}
									</div>
									
									</div>
							</div>
	
	
							{/* <div
							style={{
								width:mobileDeviceTrue ? "100%" : "50%",
								opacity:image == "instagram" ? "1" : "0",
								transition: "all 0.3s ease-in"
							}}>
								<div
								style={{
									width:"100%",
									display:"flex",
									justifyContent:"center",
	
								}}>
									<div className="" 
									style={{
										display:"flex",
										justifyContent:"center",
										minHeight:"",
										minWidth:"",
									}}
									>
										<img 
										src="https://storage.googleapis.com/calendarapp-data-bucket/LandingPage/Restaurant%20Demo%201.png" 
										height={mobileDeviceTrue ? "95%" : "100%"} 
										width=""
										/>
	
									</div>
									
									</div>
							</div>
	
							<div
							style={{
								width:mobileDeviceTrue ? "100%" : "50%",
								opacity:image == "whatsapp" ? "1" : "0",
								transition: "all 0.3s ease-in"
							}}>
								<div
								style={{
									width:"100%",
									display:"flex",
									justifyContent:"center",
	
								}}>
									<div className="" 
									style={{
										display:"flex",
										justifyContent:"center",
										minHeight:"",
										minWidth:"",
									}}
									>
										<img 
										src="https://storage.googleapis.com/calendarapp-data-bucket/LandingPage/Restaurant%20Demo%201.png" 
										height={mobileDeviceTrue ? "95%" : "100%"} 
										width=""
										/>
	
									</div>
									
									</div>
							</div>
	
							<div
							style={{
								width:mobileDeviceTrue ? "100%" : "50%",
								opacity:image == "telegram" ? "1" : "0",
								transition: "all 0.3s ease-in"
							}}>
								<div
								style={{
									width:"100%",
									display:"flex",
									justifyContent:"center",
	
								}}>
									<div className="" 
									style={{
										display:"flex",
										justifyContent:"center",
										minHeight:"",
										minWidth:"",
									}}
									>
										<img 
										src="https://storage.googleapis.com/calendarapp-data-bucket/LandingPage/Restaurant%20Demo%201.png" 
										height={mobileDeviceTrue ? "95%" : "100%"} 
										width=""
										/>
	
									</div>
									
									</div>
							</div>
	
							<div
							style={{
								width:mobileDeviceTrue ? "100%" : "50%",
								opacity:image == "google" ? "1" : "0",
								transition: "all 0.3s ease-in"
							}}>
								<div
								style={{
									width:"100%",
									display:"flex",
									justifyContent:"center",
	
								}}>
									<div className="" 
									style={{
										display:"flex",
										justifyContent:"center",
										minHeight:"",
										minWidth:"",
									}}
									>
										<img 
										src="https://storage.googleapis.com/calendarapp-data-bucket/LandingPage/Restaurant%20Demo%201.png" 
										height={mobileDeviceTrue ? "95%" : "100%"} 
										width=""
										/>
	
									</div>
									
									</div>
							</div> */}
	
	
						</div>
	
					</div>
					
				</div>
			</>
		)
	}

	const UseCaseBox = () => {
		const mobileDeviceTrue = useMediaQuery('(max-width:600px)');
	
		return (
			<>
				<div style={{margin:"5rem"}}></div>

				<div className="container" style={{margin:"0 auto 10vh"}}>
					<div className="" style={{textAlign:"center"}}>

						<div className=" mt-2" style={{textAlign:"center"}}> 
							<h2 style={{marginBottom:"3rem"}}>Vermietungsbeispiele</h2>
						</div>
						<div className="w-100 mt-2"></div>
	
						<div className="img3- lp-testimonial-box" 
						style={{
							// background:"#F2F2F2",
							// borderRadius:"20px"
						}}
					>
						
							
							
							<div className="lp-testimonial flex-column-center"
							style={{
								width:mobileDeviceTrue ? "" :"33%",
								maxWidth:"unset"
							}}>
								<div>
									<img src="https://storage.googleapis.com/calendarapp-data-bucket/holiday%20house%20city.jpg" width="100%"
									style={{
										borderRadius:"20px"
									}}/>
								</div>
								<div style={{textAlign:"center",marginTop:"1rem"}}>
									<h4
									style={{
										fontWeight:"400",
										fontSize:"18px"
									}}>
									<strong>3 City Apartments </strong>
									</h4>
								</div>
								<div className="marginb-3"
								style={{
									margin:"10px 0"
								}}>
										<button
										className="btn-cta1"
										style={{
											color:"#4CAF50",
											backgroundColor:"white",
											fontSize: "14px",
										}}>
											<div
											className='f'
											>
												<div>Beispiel ansehen</div>
											</div>
											
											
										</button>
									</div>
							</div>
							
							<div className="lp-testimonial flex-column-center"
							style={{
								width:mobileDeviceTrue ? "" :"33%",
								maxWidth:"unset"
							}}>
								<div>
									<img src="https://storage.googleapis.com/calendarapp-data-bucket/holiday%20house%20nordsee.jpg" width="100%"
									style={{
										borderRadius:"20px"
									}}/>
								</div>
								<div style={{textAlign:"center",marginTop:"1rem"}}>
									<h4
									style={{
										fontWeight:"400",
										fontSize:"18px",
									}}>
									<strong>Fewo an der Nordsee </strong>
									</h4>
								</div>
								<div className="marginb-3"
								style={{
									margin:"10px 0"
								}}>
										<button
										className="btn-cta1"
										style={{
											color:"#4CAF50",
											backgroundColor:"white",
											fontSize: "14px",

										}}>
											<div
											className='f'
											>
												<div>Beispiel ansehen</div>
											</div>
											
											
										</button>
									</div>
							</div>
							
							<div className="lp-testimonial flex-column-center"
							style={{
								width:mobileDeviceTrue ? "" :"33%",
								maxWidth:"unset"
							}}>
								<div>
									<img src="https://storage.googleapis.com/calendarapp-data-bucket/holiday%20house%20winter.jpg" width="100%"
									style={{
										borderRadius:"20px"
									}}/>
								</div>
								
								<div style={{textAlign:"center",marginTop:"1rem"}}>
									<h4
									style={{
										fontWeight:"400",
										fontSize:"18px",
									}}>
									<strong>Luxoriöse Hütten in den Alpen </strong>
									</h4>
								</div>
								<div className="marginb-3"
								style={{
									margin:"10px 0"
								}}>
										<button
										className="btn-cta1"
										style={{
											color:"#4CAF50",
											backgroundColor:"white",
											fontSize: "14px",

										}}>
											<div
											className='f'
											>
												<div>Beispiel ansehen</div>
											</div>
											
											
										</button>
									</div>
							</div>
	
						</div>
					</div>
	
				</div>
			</>
		)
	}

	const mobileDeviceTrue = useMediaQuery('(max-width:600px)');
	
    return (
        <>
            <Header />

				<TitleBox />
				<ConnectionBox />
				<USPBox />
				<PricingBox />
				<PlatformBox />
				<TestimonialBox />
				<div style={{margin:mobileDeviceTrue ? "5rem" : ""}}></div>
				<FAQBoxFewo />
				<UseCaseBox />
				{/* <LandingPageReminders /> */}

            <Footer />
			<CalendarAppCookieConsent />
        </>
    )
}

export default LandingPageFewo
