import Register from "./../Register"
import CheckIcon from '@mui/icons-material/Check';
import useMediaQuery from '@mui/material/useMediaQuery';


const PricingBox = () => {

	const mobileDeviceTrue = useMediaQuery('(max-width:600px)');

    return (
        <>
        <div className="container">
            <div className="row" style={{textAlign:"center",marginTop:"15rem"}}>
                <div className="col mt-3 mb-3">
                    <h2 id="pricing">Pricing</h2>
                </div>
                <div className="w-100"></div>
				<div 
				className="pricing"
					// style={{display:"flex",justifyContent:"center",padding:"2rem"}}
					>
					<div style={{display:"flex",justifyContent:"center"}}>
						<div className="pricingBox" >
							<div className="pricingBoxContent" 
							>
								<h4>Free Trail</h4>
							</div>
							<div 
							style={{
								display:"flex",
							}}>
								<div
								style={{
									fontSize: "53px",
									fontWeight: "bold",
								}}>
									Free
								</div>
								<div
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "end",
									padding: "0 0 15px 0"
								}}>
								</div>
							</div>

							<div style={{
								textAlign:"left",
								margin:"1rem 0"
							}}>
								<div>
									Test the CalendarApp for 14 days
								</div>
							</div>

							<div style={{
								display:"flex",
								margin:"5px 0"
							}}>
								<div>
									<CheckIcon />
								</div>
								<div
								className="flex-column-center">
									<div
									style={{
										margin:"0 0 0 10px",
										fontWeight:"bold"
									}}>
										1 Google Account
									</div>
								</div>
							</div>

							<div style={{
								display:"flex",
								margin:"5px 0"
							}}>
								<div>
									<CheckIcon />
								</div>
								<div
								className="flex-column-center">
									<div
									style={{
										margin:"0 0 0 10px",
										fontWeight:"bold"
									}}>
										10 Calendars & Event Types
									</div>
								</div>
							</div>

							<div style={{
								display:"flex",
								margin:"5px 0"
							}}>
								<div>
									<CheckIcon />
								</div>
								<div
								className="flex-column-center">
									<div
									style={{
										margin:"0 0 0 10px",
										fontWeight:"bold"
									}}>
										Support via Text,Call & Video Call
									</div>
								</div>
							</div>

							<div style={{
								display:"flex",
								margin:"5px 0px 1rem 0"
							}}>
								<div>
									<CheckIcon />
								</div>
								<div
								className="flex-column-center">
									<div
									style={{
										margin:"0 0 0 10px",
										fontWeight:"bold"
									}}>
										no credit card required
									</div>
								</div>
							</div>

							<div className="col mt-3"
							style={{
							}}
							>
								<Register
								buttonText="Start Free Trail"
								referrer="freeTrail"
								/>
							</div>
						</div>
					</div>
					<div style={{display:"flex",justifyContent:"center"}}>
						<div className="pricingBox" >
							<div className="pricingBoxContent" 
							>
								<h4>Professional</h4>
							</div>
							<div 
							style={{
								display:"flex",
							}}>
								<div
								style={{
									fontSize: "53px",
									fontWeight: "bold",
								}}>
									$10
								</div>
								<div
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "end",
									padding: "0 0 15px 0"
								}}>
									/month
								</div>
							</div>

							<div style={{
								textAlign:"left",
								margin:"1rem 0"
							}}>
								<div>
									Scheduele Appointments Fast
								</div>
							</div>

							<div style={{
								display:"flex",
								margin:"5px 0"
							}}>
								<div>
									<CheckIcon />
								</div>
								<div
								className="flex-column-center">
									<div
									style={{
										margin:"0 0 0 10px",
										fontWeight:"bold"
									}}>
										1 Google Account
									</div>
								</div>
							</div>

							<div style={{
								display:"flex",
								margin:"5px 0"
							}}>
								<div>
									<CheckIcon />
								</div>
								<div
								className="flex-column-center">
									<div
									style={{
										margin:"0 0 0 10px",
										fontWeight:"bold"
									}}>
										Unlimited Calendars & Event Types
									</div>
								</div>
							</div>

							<div style={{
								display:"flex",
								margin:"5px 0"
							}}>
								<div>
									<CheckIcon />
								</div>
								<div
								className="flex-column-center">
									<div
									style={{
										margin:"0 0 0 10px",
										fontWeight:"bold"
									}}>
										Support via Text,Call & Video Call
									</div>
								</div>
							</div>

							<div style={{
								display:"flex",
								margin:"5px 0px 1rem 0"
							}}>
								<div>
									<CheckIcon />
								</div>
								<div
								className="flex-column-center">
									<div
									style={{
										margin:"0 0 0 10px",
										fontWeight:"bold"
									}}>
										All updates included
									</div>
								</div>
							</div>

							<div className="col mt-3"
							style={{
							}}
							>
								<Register
								buttonText="Get Professional"
								referrer="professional"
								/>
							</div>
						</div>
					</div>

					<div style={{display:"flex",justifyContent:"center"}}>
						<div className="pricingBox" >
							<div className="pricingBoxContent" 
							>
								<h4>Lifetime Deal</h4>
							</div>
							<div 
							style={{
								display:"flex",
							}}>
								<div
								style={{
									fontSize: "53px",
									fontWeight: "bold",
								}}>
									$297
								</div>
								<div
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "end",
									padding: "0 0 15px 0"
								}}>
									/one time payment
								</div>
							</div>

							<div style={{
								textAlign:"left",
								margin:"1rem 0"
							}}>
								<div>
									Never worry about scheduling ever again
								</div>
							</div>

							<div style={{
								display:"flex",
								margin:"5px 0"
							}}>
								<div>
									<CheckIcon />
								</div>
								<div
								className="flex-column-center">
									<div
									style={{
										margin:"0 0 0 10px",
										fontWeight:"bold"
									}}>
										unlimited Google Accounts
									</div>
								</div>
							</div>

							<div style={{
								display:"flex",
								margin:"5px 0"
							}}>
								<div>
									<CheckIcon />
								</div>
								<div
								className="flex-column-center">
									<div
									style={{
										margin:"0 0 0 10px",
										fontWeight:"bold"
									}}>
										Unlimited Calendars & Event Types
									</div>
								</div>
							</div>

							<div style={{
								display:"flex",
								margin:"5px 0"
							}}>
								<div>
									<CheckIcon />
								</div>
								<div
								className="flex-column-center">
									<div
									style={{
										margin:"0 0 0 10px",
										fontWeight:"bold"
									}}>
										Priority Support 
									</div>
								</div>
							</div>

							<div style={{
								display:"flex",
								margin:"5px 0px 1rem 0"
							}}>
								<div>
									<CheckIcon />
								</div>
								<div
								className="flex-column-center">
									<div
									style={{
										margin:"0 0 0 10px",
										fontWeight:"bold"
									}}>
										All updates included
									</div>
								</div>
							</div>

							<div className="col mt-3"
							style={{
							}}
							>
								<Register
								buttonText="Get Life Time"
								referrer="professional"
								 />
							</div>
						</div>
					</div>
					
					
				</div>
                
            </div>

        </div>
        </>
    )
}

export default PricingBox
