import React from 'react'
import Skeleton from "@material-ui/lab/Skeleton"

const SkeletonCalendar = () => {
    return (
        <>
            <div className="container" style={{
                margin:"3rem auto",
                maxWidth:"350px",
                border:"1px solid #dadce0"}}>
            
            <div style={{display:"flex",justifyContent:"center",marginTop:"1rem"}}>
                <Skeleton variant="circle" width={80} height={80}/>
            </div>
            <div style={{marginTop:"1rem"}}>
                <Skeleton variant="text" animation="wave" height={40}/>
            </div>
            <div style={{marginTop:"1rem"}}>
                <Skeleton variant="text" width={250}/>
                <Skeleton variant="text" width={250}/>
                <Skeleton variant="text" width={250}/>
            </div>
            <div style={{marginTop:"1rem"}}>
                <Skeleton variant="rect" height={250}/>
            </div>
            <Skeleton variant="text" height={60} />
            <Skeleton variant="text" height={60} />
            </div>
            <div style={{marginTop:"2rem"}}></div>
        </>
    )
}

export default SkeletonCalendar
