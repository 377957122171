import React from 'react'
import Nav from 'react-bootstrap/Nav'

import SignIn from "./../SignIn"
// import Register from "./../Register"
import { HashLink as Link } from 'react-router-hash-link';
import useMediaQuery from '@mui/material/useMediaQuery';

const Header = () => {

	const mobileDeviceTrue = useMediaQuery('(max-width:600px)');
    return (
        <>
        <div className="lp-header" 
		// style={{position:"relative",width:"100%",padding:"1rem"}}
		>
		<Nav
		activeKey="/home"
		onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
		style={{
			justifyContent:"space-between",
			padding: mobileDeviceTrue ? "20px 0 15px 0" : "",
			margin: "0 auto",
    		width: mobileDeviceTrue ? "" : "80%",
		}}
		>

		<Nav.Item className="flex-column-center">
			<div 
			style={{
				display:"flex"
			}}>
				<div
				style={{
					margin:"0 10px"
				}}>
					<img src="https://storage.googleapis.com/calendarapp-data-bucket/Logo/logo192.png" alt="CalendarApp Logo" height="50px"/>
				</div>
				<div
				className="flex-column-center">
					<div>
						<strong
						style={{
							fontSize:"22px"
							// color:"#F6A317"
						}}>CalendarApp</strong>

					</div>
				</div>
			</div>
		</Nav.Item>

		<div
		className="f">
			<Nav.Item className="flex-column-center">
				<div 
				style={{
					margin:"0 20px"
				}}>
					<Link to="/#demo" 
					style={{
						color: "black",
						textDecoration: "none",
						fontSize:"16px",
						fontWeight:"bold"
					}}>
						{/* <h4>Demo</h4> */}
						Demo
					</Link>
				</div>
			</Nav.Item>
			<Nav.Item className="flex-column-center">
				<div style={{
					margin:"0 20px"
				}} >
					<Link to="/#pricing" 
					style={{
						color: "black",
						textDecoration: "none",
						fontSize:"16px",
						fontWeight:"bold"
					}}>
						{/* <h4>Pricing</h4> */}
						Pricing
					</Link>
				</div>
			</Nav.Item>

			<Nav.Item className="hide-on-mobile">
				<Nav.Link>
					<div style={{display:"flex",justifyContent:"center",minHeight:"70px",
				minWidth: "230px",flexDirection: "column"}}>
					<div style={{minWidth:"70px"}}>
						<SignIn
						googleSignInButtonBackgroundColor={"rgb(242, 242, 242)"} />
					</div>
						{/* <Register /> */}
					</div>
				
				</Nav.Link>
			</Nav.Item>
		</div>

	
    </Nav>
    </div>
        </>
    )
}

export default Header
