import {useEffect, useState} from 'react'
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';


const DemoPopper = ({open,anchorElDemoPopper,setAnchorElDemoPopper,setOpenDemoPopper,useCase}) => {


	const handleCloseDemoPopper = () => {
		setOpenDemoPopper(false)
		setAnchorElDemoPopper(null);
	  
	};

	const [desktopUser, setDesktopUser] = useState(false);


	useEffect(() => {
		if(navigator.userAgent.indexOf("Win" || "Mac" || "Linux" ) != -1){
			setDesktopUser(true)
		}else{
			setDesktopUser(false)
		}

	},[])

	const startDemo = (channel) => {

		if(useCase === "restaurant"){
			if(channel === "whatsapp"){
				if(desktopUser){
					window.open("https://wa.me/4915737054095?text=w17523136","")
				}else{
					window.open("whatsapp://send?phone=+4915737054095&text=I want to get a demo of the CalendarApp's restaurant use case!","")
				}
			}
			if(channel === "messenger"){
				if(desktopUser){
					window.open("https://m.me/100331455825427?ref=w17047278","")
				}else{
					window.open("https://m.me/100331455825427?ref=w17047278","")
				}
			}
			if(channel === "instagram"){
				if(desktopUser){
					window.open("https://ig.me/m/calendar_app.de","")
				}else{
					window.open("https://ig.me/m/calendar_app.de","")
				}
			}
			if(channel === "telegram"){
				if(desktopUser){
					window.open("https://t.me/CalendarApp_bot?start=w17522070","")
				}else{
					window.open("tg://CalendarApp_bot?start=w17522070","")
				}
			}
		}
		if(useCase === "real estate"){
			if(channel === "whatsapp"){
				if(desktopUser){
					window.open("https://wa.me/4915737054095?text=I want to get a demo of the CalendarApp's real estate use case!","")
				}else{
					window.open("whatsapp://send?phone=+4915737054095&text=I want to get a demo of the CalendarApp's real estate use case!","")
				}
			}
			if(channel === "messenger"){
				if(desktopUser){
					window.open("https://m.me/100331455825427?ref=WebsiteDemo","")
				}else{
					window.open("https://m.me/100331455825427?ref=WebsiteDemo","")
				}
			}
			if(channel === "instagram"){
				if(desktopUser){
					window.open("https://ig.me/m/calendar_app.de","")
				}else{
					window.open("https://ig.me/m/calendar_app.de","")
				}
			}
			if(channel === "telegram"){
				if(desktopUser){
					window.open("https://t.me/CalendarApp_bot?start=w17526611","")
				}else{
					window.open("tg://CalendarApp_bot?start=w17526611","")
				}
			}
		}
	}

  return (
	<div>
		<div>
				
				<Popover
					// id={idDemoPopper}
					open={open}
					anchorEl={anchorElDemoPopper}
					onClose={handleCloseDemoPopper}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					
				>
					<div
					style={{
						maxWidth:"300px",
					}}>
						<div
						style={{
						}}>
							<div
							className="popper-element"
							onClick={() => {
								startDemo("whatsapp")
							}}
							>
								<div
								style={{
									padding:"0 10px"
								}}>
									<img loading="" border="0" alt="telegram" src="https://deroche-moore.de/wp-content/uploads/2022/03/whatsapp.png" height="30px" width="30px" className="hover"/>
								</div>
								<div
								className="flex-column-center"
								style={{
									fontWeight:"bold"
								}}> Whatsapp</div>
							</div>
							<div
							className="popper-element"
							onClick={() => {
								startDemo("messenger")
							}}
							>
								<div
								style={{
									padding:"0 10px"
								}}>
									<img loading="" border="0" alt="telegram" src="https://deroche-moore.de/wp-content/uploads/2022/03/messenger.png" height="30px" width="30px" className="hover"/>
								</div>
								<div
								className="flex-column-center"
								style={{
									fontWeight:"bold"
								}}> Messenger</div>
							</div>
							<div
							className="popper-element"
							onClick={() => {
								startDemo("instagram")
							}}
							>
								<div
								style={{
									padding:"0 10px"
								}}>
								<img loading="" border="0" alt="telegram" src="https://deroche-moore.de/wp-content/uploads/2022/03/instagram.png" height="30px" width="30px" className="hover"/>

								</div>
								<div
								className="flex-column-center"
								style={{
									fontWeight:"bold"
								}}> Instagram</div>
							</div>
							<div
							className="popper-element"
							onClick={() => {
								startDemo("telegram")
							}}
							>
								<div
								style={{
									padding:"0 10px"
								}}>
									<img loading="" border="0" alt="telegram" src="https://deroche-moore.de/wp-content/uploads/2022/03/telegram.png" height="30px" width="30px" className="hover"/>
								</div>
								<div
								className="flex-column-center"
								style={{
									fontWeight:"bold"
								}}> Telegram</div>
							</div>
						</div>
					</div>
				</Popover>
				
			</div>
	</div>
  )
}

export default DemoPopper