import React from 'react'
import Register from '../Register'
import useMediaQuery from '@mui/material/useMediaQuery';

const TitleBox = () => {

	const mobileDeviceTrue = useMediaQuery('(max-width:600px)');

    return (
        <>
            <div className="container">
                <div className="row" style={{textAlign:"center"}}>
					<div className="colhead"
					style={{
						display:"flex",
						flexDirection:"",
						height:mobileDeviceTrue ? "" : "100vh"
					}}
					>
						<div className="col2-left" style={{}}>
						<div 
						className="lp-titlebox-text"
						style={{
						}}
						>
							<div style={{marginBottom:"2rem"}}>
								<h1>Appointment booking</h1>
							</div>
							<h2
							style={{
								fontWeight:"400"
							}}>
								The most elegant way to schedule appointments with Manychat and your Google Calendar across various industries
							</h2>
							{/* <h2>Automate your booking process conversationally</h2> */}
							<div 
							className='lp-cta-btn'
							style={{}}
							>
								{/* <button>Get started for free</button> */}
								<div className="marginb-3">
									<Register className="button-save"
									referrer="getStarted" />
								</div>
							</div>
						</div>
							
						</div>
						<div className="col" 
						style={{display:"flex",justifyContent:"center",
						minHeight:"",minWidth:"50%",padding:"1rem 0 0 0"}}
						>
							{/* <img src="https://deroche-moore.de/wp-content/uploads/2022/05/Calendar-App-Open-House-image.png" height="80%"/> */}
							{/* <iframe 
								width="370px" 
								height="700px" 
								src="https://storage.cloud.google.com/calendarapp-data-bucket/Demo%20Start%20Page.webm"
								title="View a demo of the CalendarApp" 
								frameborder="0" 
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
								allowfullscreen
							>
							

							</iframe> */}
						<video autoplay muted
						width={mobileDeviceTrue ? "95%" : "80%"}
						height={mobileDeviceTrue ? "95%" : "80%"}
						autoPlay={true}
						loop={true}
						style={{
							display:"flex",
							margin:"0 auto"
						}}
						>
						<source src="https://storage.googleapis.com/calendarapp-data-bucket/LandingPage/CalendarApp%20Demo.webm" type="video/webm"></source>
						<source src="https://storage.googleapis.com/calendarapp-data-bucket/CalendarApp%20Demo.mp4" type="video/mp4"></source>

						Sorry, your browser doesn't support embedded videos.
						</video>
						</div>
					</div>
                    <div className="w-100" style={{marginBottom:"3rem"}}></div>
                    <div className="col mt-2" style={{textAlign:"center"}}> 
                        <h2 style={{marginBottom:"3rem"}}>Trusted by these Manychat Experts</h2>
                    </div>
                    <div className="w-100 mt-2"></div>

					<div className="img3-col lp-testimonial-box" 
					style={{
						background:"#F2F2F2",
						borderRadius:"20px"
					}}
				>
						<div className="lp-testimonial flex-column-center">
							<div>
								<img src="https://storage.googleapis.com/calendarapp-data-bucket/Logo/CalendarApp%20Andrei.png" height="120px"/>
							</div>
							<div>
								<h5>Andrei</h5>
							</div>
							<div>
								<h5>Founder of Chatbotprofi</h5>
							</div>
							<div style={{textAlign:"left",marginTop:"2rem"}}>
								<h5
								style={{
									fontWeight:"400"
								}}>
								Here is the most modern and powerful tool for your business. Previously, there was a need for various applications. Now everything can be achieved with one simple tool. Try and see for yourself. How easy it is to solve problems for your business.</h5>
							</div>
						</div>
						
						<div className="lp-testimonial flex-column-center">
							<div className="mb-2">
								<img src="https://storage.googleapis.com/calendarapp-data-bucket/Logo/CalendarApp%20Sami.png" height="120px"/>
							</div>
							<div>
								<h5>Sami</h5>
							</div>
							<div>
								<h5>Founder of Deroché &  Moore</h5>
							</div>
							<div style={{textAlign:"left",marginTop:"2rem"}}>
								<h5
								style={{
									fontWeight:"400"
								}}>There is nothing alike the CalendarApp!

								The tool is perfectly optimised for conversational scheduling across multiple platforms.

								We use it for our clients across various niches. We've been loving it from the first moment.</h5>
							</div>
						</div>
						<div className="lp-testimonial flex-column-center">
							<div>
								<img src="https://storage.googleapis.com/calendarapp-data-bucket/Logo/CalendarApp%20Joyson.png" height="120px"/>
							</div>
							<div>
								<h5>Joyson</h5>
							</div>
							<div>
								<h5>CEO of Chatby</h5>
							</div>
							<div style={{textAlign:"left",marginTop:"2rem"}}>
								<h5
								style={{
									fontWeight:"400"
								}}>We are very happy to use the CalendarApp on a daily bases introducing an astonishing level of automation into our clients' businesses that has never happened before. </h5>
							</div>
						</div>

					</div>
                </div>

            </div>
        </>
    )
}

export default TitleBox
