import {useState} from 'react'
import useMediaQuery from '@mui/material/useMediaQuery';

const PlatformBox = () => {

	const mobileDeviceTrue = useMediaQuery('(max-width:600px)');

    const customStyles = {
        container: base => ({
            ...base,
            flex:1
        })
    }

    const platformOptions = [
        {value:"Whatsapp", label:"Whatsapp"},
        {value:"Instagram", label:"Instagram"},
        {value:"Google Maps", label:"Google Maps"},
        {value:"Telegram", label:"Telegram"},
        {value:"LinkedIn", label:"LinkedIn"},
        {value:"Signal", label:"Signal"},
        {value:"WeChat", label:"WeChat"},
        {value:"iMessage", label:"iMessage"}
    ]

	const [image,setImage] = useState("messenger")
	const [activeImageId, setActiveImageId] = useState(1);

	const images = [
		{ src: 'https://storage.googleapis.com/calendarapp-data-bucket/Logo/Platform%20Messenger.png', id: 1 },
		{ src: 'https://storage.googleapis.com/calendarapp-data-bucket/Logo/Platform%20Instagram.png', id: 2 },
		{ src: 'https://storage.googleapis.com/calendarapp-data-bucket/Logo/Platform%20Whatsapp.png', id: 3 },
		{ src: 'https://storage.googleapis.com/calendarapp-data-bucket/Logo/Platform%20Telegram.png', id: 4 },
		{ src: 'https://storage.googleapis.com/calendarapp-data-bucket/Logo/Platform%20Google.png', id: 5 },
	  ];

    return (
        <>
            <div className="" style={{marginTop:"15rem",height:mobileDeviceTrue ? "" : "100vh",display:"flex",flexDirection:"column",justifyContent:"space-around",width:""}}>
				<div
				style={{
					width:"80%",
					margin:"0 auto"
				}}>
                    <div className="">
						<h2 style={{marginBottom:"3rem",textAlign:"center"}}>
							Schedule across Social Media
						</h2>
					</div>

					<div
					className='f'
					style={{
						width:"100%",
						height:"100%",
						flexDirection:mobileDeviceTrue ? "column" : "row"
					}}>
						<div
						style={{
							width:mobileDeviceTrue ? "100%" : "50%",
							display:"flex",
							flexDirection:mobileDeviceTrue ? "row" : "column"
						}}>

							<div onClick={() => setActiveImageId(1)}
							className=""
							style={{
								height:mobileDeviceTrue ? "" : "80px",
								margin:mobileDeviceTrue ? "" : "30px"
								}}>
								<div className={mobileDeviceTrue ? "" : 'lp-background'}>
									<div className='f'
									style={{
										width:"100%",
										flexDirection:mobileDeviceTrue ? "column" : "row"
									}}>
										<div
										style={{
											width:"60px",
											margin:mobileDeviceTrue ? "0 10px 0 0" : "0 20px 0 0"
										}}>
											<img loading="lazy" border="0" alt="telegram" src="https://storage.googleapis.com/calendarapp-data-bucket/Logo/messenger.png"
											height={mobileDeviceTrue ? "40px" : "60px"} 
											width={mobileDeviceTrue ? "40px" : "60px"}  
											/>
										</div>
										{mobileDeviceTrue ? null : <div className='flex-column-center'>
											<h3 style={{
											fontWeight:"400"
											}}> Facebook Messenger</h3>
										</div>}
										
									</div>
								</div>
							</div>

							<div onClick={() => setActiveImageId(2)}
							
							className="" 
							style={{
								height:mobileDeviceTrue ? "" : "80px",
								margin:mobileDeviceTrue ? "" : "30px"
							}}>
								<div className={mobileDeviceTrue ? "" : 'lp-background'}>
									<div className='f'
									style={{
										width:"100%"
									}}>
										<div
										style={{
											width:"60px",
											margin:mobileDeviceTrue ? "0 10px 0 0" : "0 20px 0 0"
										}}>
											<img loading="lazy" border="0" alt="telegram" src="https://storage.googleapis.com/calendarapp-data-bucket/Logo/instagram.png"
											height={mobileDeviceTrue ? "40px" : "60px"} 
											width={mobileDeviceTrue ? "40px" : "60px"}  
											/>
										</div>
										{mobileDeviceTrue ? null : <div className='flex-column-center'>
											<h3 style={{
											fontWeight:"400"
											}}> Instagram</h3>
										</div>}
									</div>
									
								</div>
							</div>

							<div onClick={() => setActiveImageId(3)} className="" 
							style={{
								height:mobileDeviceTrue ? "" : "80px",
								margin:mobileDeviceTrue ? "" : "30px"
							}}>
								<div className={mobileDeviceTrue ? "" : 'lp-background'}>
									<div className='f'
									style={{
										width:"100%"
									}}>
										<div
										style={{
											width:"60px",
											margin:mobileDeviceTrue ? "0 10px 0 0" : "0 20px 0 0"
										}}>
											<img loading="lazy" border="0" alt="telegram" src="https://storage.googleapis.com/calendarapp-data-bucket/Logo/whatsapp.png"
											height={mobileDeviceTrue ? "40px" : "60px"} 
											width={mobileDeviceTrue ? "40px" : "60px"}  
											/>
										</div>
										{mobileDeviceTrue ? null : <div className='flex-column-center'>
											<h3 style={{
											fontWeight:"400"
											}}> Whatsapp</h3>
										</div>}
									</div>
									
								</div>
							</div>

							<div onClick={() => setActiveImageId(4)} className="" 
							style={{
								height:mobileDeviceTrue ? "" : "80px",
								margin:mobileDeviceTrue ? "" : "30px"
							}}>
								<div className={mobileDeviceTrue ? "" : 'lp-background'}>
									<div className='f'
									style={{
										width:"100%"
									}}>
										<div
										style={{
											width:"60px",
											margin:mobileDeviceTrue ? "0 10px 0 0" : "0 20px 0 0"
										}}>
											<img loading="lazy" border="0" alt="telegram" src="https://storage.googleapis.com/calendarapp-data-bucket/Logo/telegram.png"
											height={mobileDeviceTrue ? "40px" : "60px"} 
											width={mobileDeviceTrue ? "40px" : "60px"}  
											/>
										</div>
										{mobileDeviceTrue ? null : <div className='flex-column-center'>
											<h3 style={{
											fontWeight:"400"
											}}> Telegram</h3>
										</div>}
									</div>
									
								</div>
							</div>

							<div onClick={() => setActiveImageId(5)} className="" 
							style={{
								height:mobileDeviceTrue ? "" : "80px",
								margin:mobileDeviceTrue ? "" : "30px"
							}}>
								<div className={mobileDeviceTrue ? "" : 'lp-background'}>
									<div className='f'
									style={{
										width:"100%"
									}}>
										<div
										style={{
											width:"60px",
											margin:mobileDeviceTrue ? "0 10px 0 0" : "0 20px 0 0"
										}}>
											<img loading="lazy" border="0" alt="telegram" src="https://storage.googleapis.com/calendarapp-data-bucket/Logo/google%20maps%20logo.png"
											height={mobileDeviceTrue ? "40px" : "60px"} 
											width={mobileDeviceTrue ? "40px" : "60px"}  
											/>
										</div>
										{mobileDeviceTrue ? null : <div className='flex-column-center'>
											<h3 style={{
											fontWeight:"400"
											}}> Google Messages</h3>
										</div>}
									</div>
									
								</div>
							</div>

						</div>

						
						<div
						id="messenger"
						style={{
							width:mobileDeviceTrue ? "100%" : "50%",
						}}>
							<div
							style={{
								width:"100%",
								display:"flex",
								justifyContent:"center",

							}}>
								<div className="col" 
								style={{
									display:"flex",
									justifyContent:"center",
									minHeight:"",
									minWidth:"",
								}}
								>
									{images.map(({ src, id }) => (
									<img
										key={id}
										src={src}
										className={`platform-image-lp ${activeImageId === id ? 'active' : ''}`}
										alt={`Image ${id}`}
										/>
									))}
								</div>
								
								</div>
						</div>


						{/* <div
						style={{
							width:mobileDeviceTrue ? "100%" : "50%",
							opacity:image == "instagram" ? "1" : "0",
							transition: "all 0.3s ease-in"
						}}>
							<div
							style={{
								width:"100%",
								display:"flex",
								justifyContent:"center",

							}}>
								<div className="col" 
								style={{
									display:"flex",
									justifyContent:"center",
									minHeight:"",
									minWidth:"",
								}}
								>
									<img 
									src="https://storage.googleapis.com/calendarapp-data-bucket/LandingPage/Restaurant%20Demo%201.png" 
									height={mobileDeviceTrue ? "95%" : "100%"} 
									width=""
									/>

								</div>
								
								</div>
						</div>

						<div
						style={{
							width:mobileDeviceTrue ? "100%" : "50%",
							opacity:image == "whatsapp" ? "1" : "0",
							transition: "all 0.3s ease-in"
						}}>
							<div
							style={{
								width:"100%",
								display:"flex",
								justifyContent:"center",

							}}>
								<div className="col" 
								style={{
									display:"flex",
									justifyContent:"center",
									minHeight:"",
									minWidth:"",
								}}
								>
									<img 
									src="https://storage.googleapis.com/calendarapp-data-bucket/LandingPage/Restaurant%20Demo%201.png" 
									height={mobileDeviceTrue ? "95%" : "100%"} 
									width=""
									/>

								</div>
								
								</div>
						</div>

						<div
						style={{
							width:mobileDeviceTrue ? "100%" : "50%",
							opacity:image == "telegram" ? "1" : "0",
							transition: "all 0.3s ease-in"
						}}>
							<div
							style={{
								width:"100%",
								display:"flex",
								justifyContent:"center",

							}}>
								<div className="col" 
								style={{
									display:"flex",
									justifyContent:"center",
									minHeight:"",
									minWidth:"",
								}}
								>
									<img 
									src="https://storage.googleapis.com/calendarapp-data-bucket/LandingPage/Restaurant%20Demo%201.png" 
									height={mobileDeviceTrue ? "95%" : "100%"} 
									width=""
									/>

								</div>
								
								</div>
						</div>

						<div
						style={{
							width:mobileDeviceTrue ? "100%" : "50%",
							opacity:image == "google" ? "1" : "0",
							transition: "all 0.3s ease-in"
						}}>
							<div
							style={{
								width:"100%",
								display:"flex",
								justifyContent:"center",

							}}>
								<div className="col" 
								style={{
									display:"flex",
									justifyContent:"center",
									minHeight:"",
									minWidth:"",
								}}
								>
									<img 
									src="https://storage.googleapis.com/calendarapp-data-bucket/LandingPage/Restaurant%20Demo%201.png" 
									height={mobileDeviceTrue ? "95%" : "100%"} 
									width=""
									/>

								</div>
								
								</div>
						</div> */}


					</div>

				</div>
                    
                    

					{/* <div className="col">
						<img src="https://drive.google.com/uc?id=1XM3KplZ2CPgoFZ93CeD4-K2cDkirc1DV" 
						height={mobileDeviceTrue ? "80px" : "80px"} 
						width={mobileDeviceTrue ? "80px" : "80px"}  
						/>
					</div>
					<div className="col" 
					style={{height:"80px",margin:"30px"}}
					>
						<img src="https://drive.google.com/uc?id=1iLrnyjMfsBKH6KPI3PfEHtmvxEiNW5Op" 
						height={mobileDeviceTrue ? "80px" : "80px"} 
						width={mobileDeviceTrue ? "80px" : "80px"}  
						/>
					</div>
					<div className="col" style={{maxHeight:"80px",marginTop:""}}>
						<img src="https://drive.google.com/uc?id=1mkS5x_8eOAuDF-0gqxRcbbTVHR4yHKtC" 
						height={mobileDeviceTrue ? "80px" : "80px"} 
						width={mobileDeviceTrue ? "80px" : "80px"}  
						/>
					</div> */}
                
            </div>
        </>
    )
}

export default PlatformBox
