import React from 'react'
import Register from '../Register'

const LandingPageReminders = () => {
    return (
        <>
            <div 
			className="container"
			style={{
				margin:"15rem auto"
			}}
			>
                <div className="row" style={{textAlign:"center"}}>
					<div className="colhead"
					style={{
						display:"flex",
						flexDirection:"",
					}}
					>
						<div className="col" 
						style={{
							display:"flex",
							justifyContent:"center",
							minHeight:"",
							minWidth:"",
							margin:"2rem 0"
						}}
						>
							<img src="https://storage.googleapis.com/calendarapp-data-bucket/LandingPage/Send%20appointment%20reminders%20with%20the%20CalendarApp%20800px.PNG" height="90%"/>
						</div>

						<div className="col2-left" style={{}}>
							<div style={{textAlign:"left"}}>
								<div style={{
									margin:"0 0 2rem 0",
									padding:"0 10px"
								}}
								className="lp-titlebox-text"
								>
									<h2>Send Reminders</h2>
								</div>
								<div
								style={{
									padding:"0 10px"
								}}>
									<h3 style={{
								fontWeight:"400"
							}}>No customer will miss an appointment with you ever again. Send multiple reminders with recurring notifications.</h3>

								</div>
								<div 
								className='lp-cta-btn'
								style={{}}
								>
								<div className="marginb-3">
									<Register className="button-save"
									referrer="reminders"/>
								</div>
							</div>
								
							</div>
							
						</div>

						
					</div>
                    <div className="w-100" style={{marginBottom:"3rem"}}></div>
                    
                </div>

            </div>
        </>
    )
}

export default LandingPageReminders
