import React from 'react'
import { Link } from "react-router-dom"
import useMediaQuery from '@mui/material/useMediaQuery';
import MailIcon from '@mui/icons-material/Mail';

const Footer = () => {

	const mobileDeviceTrue = useMediaQuery('(max-width:600px)');

    return (
        <>
            <div className="" 
			style={{
				marginTop:"10rem",height:mobileDeviceTrue ? "" : "350px",
				backgroundColor:"#f2f2f2",
				padding:"3rem 0 "
				}}>
                <div className="row mt-15" style={{textAlign:"center"}}>
                    <div className="col">
						<div style={{marginBottom:"3rem"}}>
                        	<h2>CalendarApp</h2>
						</div>
                        <div className="w-100"></div>
                        <div className=" mb-5" style={{
							display:"flex",
							justifyContent:"space-around",
							flexDirection:mobileDeviceTrue ? "column": "row"
							}}>
                            <div className="flex-column-center">
								<div>
                                	<h6>
										Contact us
									</h6>
                                	<div
									style={{
										display:"flex",
										justifyContent:"center",
										margin:"10px 0"
									}}>
										<div className="hover">
										<MailIcon 
										color="primary"
										onClick={() =>{window.open('mailto:info@calendar-app.de?subject=Hi Sami! I have a question about the CalendarApp');}}
										/>
										<div
										className="hover"
										onClick={() =>{window.open('mailto:info@calendar-app.de?subject=Hi Sami! I have a question about the CalendarApp');}}
										style={{
											margin:"0 5px"
										}}>
											info@calendar-app.de
										</div>
										</div>
									</div>
								</div>
                            </div>
                            <div className="flex-column-center" style={{textAlign:"left"}}>
								<div>
									<Link to="/data-privacy"
									style={{
										color: "black",
										textDecoration: "none",
										font:"small-caption"
									}}>
                                		<h6>Data Privacy</h6>
									</Link>
								</div>
								<div>
									<Link to="/terms-of-service"
									style={{
										color: "black",
										textDecoration: "none",
										font:"small-caption"
									}}>
										<h6>Terms of Service</h6>
									</Link>
								</div>
                            </div>
                            
                            <div className="flex-column-center">
								<h6>
                                	Social
								</h6>
								<div
								style={{
									display:"flex",
									justifyContent:"center",
									margin:mobileDeviceTrue ? "10px 0" : ""
								}}>
									<div 
										className="hover"
										onClick={() => {
											//
											mobileDeviceTrue ? window.open("https://m.me/100331455825427?ref=w17265583","") : window.open("https://www.facebook.com/profile.php?id=100080163641985","")
										}}
										style={{margin:"0 10px 0 0"}}>
											<img  border="0" alt="" src="https://deroche-moore.de/wp-content/uploads/2022/03/messenger.png" height="20px" width="20px"/>
										</div>
									<div 
										className="hover"
										onClick={() => {
											window.open("https://www.instagram.com/calendar_app.de/","")
										}}
										
										style={{margin:"0 10px 0 0"}}><img  border="0" alt="" src="https://deroche-moore.de/wp-content/uploads/2022/03/instagram.png" height="20px" width="20px"/>
										</div>
									<div 
										className="hover"
										style={{margin:"0 10px 0 0"}}
										onClick={() => {
											mobileDeviceTrue ? window.open("whatsapp://send?phone=+4915737054095&text=Hello! Can I get a demo of the CalendarApp?","") : window.open("https://wa.me/4915737054095?text=Hello! Can I get a demo of the CalendarApp?","")
										}}
										//https://wa.me/4915737054095?text=Hello! Can I get a demo of the CalendarApp?
										//whatsapp://send?phone=+4915737054095&text=Hello! Can I get a demo of the CalendarApp?
										>
										<img  border="0" alt="" src="https://deroche-moore.de/wp-content/uploads/2022/03/whatsapp.png" height="20px" width="20px"/>
										</div>
									<div 
									className="hover"
									style={{
										margin:"0 10px 0 0"
										}}
									onClick={() => {
										window.open("https://telegram.me/calendarapp_bot","") //https://t.me/CalendarApp_bot?start=w17521554
									}}
										>
										<img  border="0" alt="" src="https://deroche-moore.de/wp-content/uploads/2022/03/telegram.png" height="20px" width="20px"/>
									</div>
									
								</div>
								
                            </div>
                            <div className="flex-column-center">
								
								<h6>
								<a href="https://www.freepik.com/vectors/calendar-cartoon">Graphics from www.freepik.com</a>
								</h6>
								<h6>
								</h6>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer
